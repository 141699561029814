import { emailRegex } from './regex'

function validateEmailField (
  value: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length === 0) {
    createErrorMethod('email', 'E-mail é obrigatório')
    return false
  }

  if (!emailRegex.test(value)) {
    createErrorMethod('email', 'Entre com um e-mail válido')
    return false
  }

  cleanErrorsByFieldname('email')
  return true
}

function validatePasswordField (
  value: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length === 0) {
    createErrorMethod('password', 'Senha é obrigatória')
    return false
  }

  if (value.length < 4) {
    createErrorMethod('password', 'Senha deve ter pelo menos 4 dígitos')
    return false
  }

  cleanErrorsByFieldname('password')
  return true
}

function validateConfirmPasswordField (
  value: string,
  password: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length === 0) {
    createErrorMethod('confirmPassword', 'Senha é obrigatória')
    return false
  }

  if (value.length < 4) {
    createErrorMethod('confirmPassword', 'Senha deve ter pelo menos 4 dígitos')
    return false
  }

  if (value !== password) {
    createErrorMethod('confirmPassword', 'As senhas não coincidem')
    return false
  }

  cleanErrorsByFieldname('confirmPassword')
  return true
}

function validateEmptyField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length === 0) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validateCardNumberField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 19) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validateCvvField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 3) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validateExpiringDateField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 5) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validateCpfField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 14) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validatePhoneField (
  value: string,
  fieldName: string,
  errorTextMessage: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 15) {
    createErrorMethod(fieldName, errorTextMessage)
    return false
  }

  cleanErrorsByFieldname(fieldName)
  return true
}

function validateCodeField (
  value: string,
  createErrorMethod: (field: string, message: string) => void,
  cleanErrorsByFieldname: (field: string) => void
): boolean {
  if (value.length !== 4) {
    createErrorMethod('code', 'Insira um código válido')
    return false
  }

  cleanErrorsByFieldname('code')
  return true
}

export {
  validateEmailField,
  validatePasswordField,
  validateConfirmPasswordField,
  validateEmptyField,
  validateCardNumberField,
  validateCvvField,
  validateExpiringDateField,
  validateCpfField,
  validatePhoneField,
  validateCodeField
}

import { LogIn } from 'lucide-react'
import styled from 'styled-components'

import rotationLock from '../../../assets/icons/rotation-lock.png'

type NotifierProps = {
  goToLoginScreen: () => void
}

const NotifierContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  text-align: center;

  .icon {
    max-width: 15rem;
    width: 40%;

    img {
      width: 100%;
    }

    @media (max-width: 350px) {
      width: 50%;
    }
  }

  p {
    color: #464646;
    font-size: 1.2rem;
    font-weight: 700;

    width: 80%;

    margin: 1.6rem 0 2rem;
  }

  button {
    background: #ff0000;
    border-radius: 2rem;
    color: #ffffff;
    font-weight: 700;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;

    padding: .6rem 0;

    svg {
      margin-right: 1.2rem;
    }
  }
`

function Notifier ({ goToLoginScreen }: NotifierProps) {
  return (
    <NotifierContainer>
      <div className="icon">
        <img src={rotationLock} alt="Cadeado" />
      </div>
      <p className='label'>Para ter acesso a esta página você precisa fazer Login.</p>
      <button type="button" onClick={goToLoginScreen}>
        <LogIn size={24} color="#ffffff" />
        <span>Fazer Login</span>
      </button>
    </NotifierContainer>
  )
}
export default Notifier

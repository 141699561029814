import { Ticket } from 'lucide-react'

type VoucherCardProps = {
  code: string,
  price: number,
  action: () => void
}

function VoucherCard({ code, price, action }: VoucherCardProps) {
  return (
    <button
      type="button"
      className="voucher_card"
      onClick={action}
    >
      <div className="icon">
        <Ticket size={28} />
      </div>
      <div className="description">
        <p className="code">{code}</p>
        <p className="discount">- R$ {price.toFixed(2)}</p>
      </div>
    </button>
  )
}

export default VoucherCard

import { Phone, User } from 'lucide-react'
import { ChangeEvent, RefObject } from 'react'
import styled from 'styled-components'
import { ErrorType } from '../../../hooks/useFormError'
import { cpfMask, phoneNumberMask } from '../../../utils/masks'
import Field from '../../../components/Field'

type MoreInfoProps = {
  moreInfoProps: {
    cpf: string,
    phone: string,
    change: (e: ChangeEvent<HTMLInputElement>) => void,
    clear: (field: string) => void,
    note: boolean,
    changeNoteInfo: () => void,
    getError: (field: string) => ErrorType | undefined,
    ref: RefObject<HTMLDivElement>
  },
}

const MoreInfoContainer = styled.div`
  padding: .8rem;

  .option {
    padding: 1.4rem 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      color: #ff0000;
      font-size: 1.2rem;
      font-weight: 700;
    }

    .slider {
      background: #888888;
      border-radius: 1.4rem;

      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      width: 5rem;
      height: 2rem;

      box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, .06);
      cursor: pointer;
      transition: background .25s ease;

      .sphere {
        background: #dddddd;
        border-radius: 50%;

        position: absolute;
        top: -.25rem;
        left: -1rem;

        width: 2.5rem;
        height: 2.5rem;

        box-shadow: 0px 0px 8px 0px rgb(0, 0, 0, .06);
        transition: background, left, .25s ease;
      }

      &.active {
        background: #ff8888;

        .sphere {
          background: #ff0000;

          left: calc(100% - 1.5rem);
        }
      }
    }
  }

  @media (min-width: 628px) {
    .fields {
      display: flex;

      > div {
        &:first-child {
          margin-right: 2rem;
        }
      }
    }
  }
`

function MoreInfo ({ moreInfoProps }: MoreInfoProps) {
  const { cpf, phone, change, clear, note, changeNoteInfo, getError, ref } = moreInfoProps

  const cpfFieldProps = {
    value: cpfMask(cpf),
    change,
    clear: () => clear('cpf'),
    placeholder: 'CPF',
    name: 'cpf',
    error: getError('cpf')
  }

  const phoneFieldProps = {
    value: phoneNumberMask(phone),
    change,
    clear: () => clear('phone'),
    placeholder: 'Telefone',
    name: 'phone',
    error: getError('phone')
  }

  return (
    <MoreInfoContainer ref={ref}>
      <div className="option">
        <span>CPF no cupom fiscal?</span>
        <button
          type="button"
          onTouchStart={changeNoteInfo}
          onMouseDown={changeNoteInfo}
          className={`slider ${note ? 'active' : ''}`}
        >
          <div className="sphere"></div>
        </button>
      </div>
      <div className="fields">
        <Field fieldProps={cpfFieldProps}>
          <User color="#ff0000" size={24} />
        </Field>
        <Field fieldProps={phoneFieldProps}>
          <Phone color="#ff0000" size={24} />
        </Field>
      </div>
    </MoreInfoContainer>
  )
}

export default MoreInfo

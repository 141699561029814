import styled from 'styled-components'

const HalfSelectorContainer = styled.div`
  background: #ffffff;

  position: fixed;
  top: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100vh;

  z-index: 10000;

  .items {
    width: 100%;

    flex: 1;

    padding: .6rem;

    overflow-y: auto;
  }

  .item_card {
    border-radius: .4rem;

    width: 100%;
    min-height: 5rem;

    display: flex;

    margin: .6rem 0 2.8rem 0;
    padding: .4rem .8rem;

    text-align: left;

    box-shadow: 0px 0px 9px -4px rgb(0 0 0 / 52%);
 
    .pricing {

      display: flex;
      justify-content: flex-end;

      .not-promotional-card {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        
      }
      
      .red-cross{
        position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.6;
      }
    
      .promotional-card{
        display: flex;
        justify-content: center;
        align-content: center;
        background: #2f760c;
        color: #fff;
        padding: 3px;
        border-radius: 3px;
        
      
      }
      .price {
        position: relative;
      }
      span {
        font-size: 1.2rem;
        font-weight: bolder;

        &.old {
          position: relative;
          color: #ff0000;
          margin-right: 1.2rem;
        }
        &.new{
          color: #2f760c;
        }
      }

      .amplified {
        font-size: 1.2rem;
      }
    }

    .image {
      max-width: 15rem;

      margin-right: 1.4rem;

      img {
        border-radius: .6rem;

        width: 100%;
      }
    }

    .item_description {
      flex: 1;
      font-size: 1.2rem;

      .title {
        font-weight: 700;

        margin-bottom: .8rem;
      }
    }
  }

  @media (min-width: 646px) {
    .items > div {
      display: flex;
      flex-wrap: wrap;
    }

    .item_card {
      width: 45%;
      margin-right: 3rem;
    }
  }

  @media (min-width: 962px) {
    .item_card {
      width: 30%;
    }
  }
`

export default HalfSelectorContainer

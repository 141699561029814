import appetizer from './appetizer.png'
import combo from './combo.png'
import dessert from './dessert.png'
import hamburger from './hamburger.png'
import pizza from './pizza.png'
import servingDish from './serving-dish.png'
import softDrink from './soft-drink.png'
import wineBottle from './wine-bottle.png'

import integral from './integral.png'
import lactose from './lactose.png'
import lowCarbDiet from './low-carb-diet.png'
import tradicional from './tradicional.png'
import border from './border.png'
import gluten from './gluten.png'
import vegana from './vegana.png'

import clock from './clock.png'
import confirm from './confirm.png'
import cooking from './cooking.png'
import delivery from './delivery.png'
import flag from './flag.png'
import prohibition from './prohibition.png'
import takeAway from './take-away.png'
import driveThru from './drive-thru.png';

import redxis from './redxis.png';

export const appIcons = {
  appetizer,
  combo,
  dessert,
  hamburger,
  pizza,
  defaultSize: servingDish,
  softDrink,
  wineBottle
}

export const complementIcons = {
  integral,
  lactose,
  lowCarbDiet,
  tradicional,
  border,
  gluten,
  vegana
}

export const requestIcons = {
  clock,
  confirm,
  cooking,
  delivery,
  flag,
  prohibition,
  takeAway,
  driveThru
}

export const markingIcons = {
  redxis
}

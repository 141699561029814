import styled from 'styled-components'

export const SearchAddressContainer = styled.div`
  background: #ffffff;
  
  position: absolute;

  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  .items {
    flex: 1;
      
    button {
      border-bottom: 1px solid #aaaaaa;

      display: flex;
      align-items: center;

      width: 100%;

      padding: 1.6rem 1.2rem;

      color: #464646;

      svg {
        margin-right: 2rem;
      }

      span {
        font-weight: 700;
      }
    }

    .span_wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      text-align: center;

      width: 100%;
      height: 100%;

      span {
        color: #464646;
        font-size: 1.4rem;
        font-weight: 700;
      }
    }
  }
`

export const AddressContainer = styled.div`
  width: 85%;

  .select_address_box {
    margin-bottom: 2rem;

    label {
      background: #ffffff;
      border: 2px solid #ffffff;
      border-radius: .6rem;

      display: flex;
      align-items: center;

      padding: 1.2rem .8rem;
      margin-bottom: 2.6rem;

      > div {
        display: flex;
        flex: 1;

        color: #464646;
      }

      input {
        flex: 1;
        width: 100%;

        font-weight: 700;

        border: 0;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: #464646;
        }        
      }

      .icon {
        margin-right: 1rem;
      }

      .clear_button {
        margin: 0 1.2rem;
      }

      .follow_button {
        background: #ff0000;
        border-radius: .6rem;
        color: #ffffff;

        font-weight: 700;

        padding: 1.2rem 1rem;
      }

      &.focus {
        border: 2px solid #1278ff;
      }

      @media (max-width: 340px) {
        flex-direction: column;

        > div {
          margin-bottom: 1rem;
        }

        .follow_button {
          width: 100%;
        }
      }
    }

    .btn_wrapper {
      width: 100%;

      text-align: center;

      button {
        font-size: 1.2rem;
        font-weight: 700;
      }
    }
  }

  .login_button {
    background: #ffffff;
    border-radius: 2.4rem;
    color: #464646;
    font-weight: 700;

    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 1rem;

    box-shadow: 0px 5px 8px -6px rgba(0,0,0);

    svg {
      margin-right: 1.4rem;
    }
  }
`

export const FieldPattern = styled.div`
  border-radius: .6rem;

  position: relative;

  padding: .8rem 0;
  padding-right: .6rem;

  .box {
    border: 2px solid #cccccc;
    border-radius: .6rem;

    position: relative;

    padding: .8rem 0;
    padding-right: .6rem;

    > button {
      width: 100%;
    }
  }

  select {
    margin-top: .3rem;
    padding-left: .2rem;

    &:focus {
      outline: 0;
    }
  }

  input, select {
    width: 100%;

    background: transparent;
    border: 0;
  }

  input {
    padding-left: 1rem;

    &:focus {
      outline: 0;
    }
  }

  label, button {
    display: flex;
    align-items: center;
  }

  button {
    input, svg, span {
      pointer-events: none;
    }
  }

  svg {
    color: #464646;
  }

  .heading {
    background: #f2f2f2;
    padding: 0 .4rem;

    position: absolute;
    top: 0;
    left: .6rem;

    color: #464646;
    font-weight: 700;
    font-size: 1.2rem;

    transform: translateY(-50%);
  }

  &.focused {
    .box {
      border: 2px solid #1278ff;
    }
  }

  .error {
    color: #ff0000;
    font-size: 1.1rem;
    font-weight: 700;

    display: block;

    margin: .6rem 0 0 .6rem;
  }
`

import { useDispatch, useSelector } from 'react-redux'
import { toggleHalfItemIngredient } from '../../../../store/features/item/itemSlice'
import { RootState } from '../../../../store/store'
import { cardapioItem, Ingredients as IngredientsType } from '../../../../types/cardapioItem'

type IngredientsProps = {
  item: cardapioItem,
  setIngredient: (arg: number) => void,
  ingredientList: IngredientsType[],
  halfItemName: string | undefined,
  halfItemIngredients: IngredientsType[] | undefined
}

function Ingredients ({ item, setIngredient, ingredientList, halfItemName, halfItemIngredients }: IngredientsProps) {
  const { halfItem } = useSelector((state: RootState) => state.item)

  const dispatch = useDispatch()

  function isHalfIngredientChecked (code: number) {
    if (!halfItem) return false

    const ingredient = halfItem.Ingredientes.find((ingredient) => ingredient.Codigo === code)

    return ingredient?.Checked
  }

  function setHalfIngredient (code: number) {
    dispatch(toggleHalfItemIngredient(code))
  }

  return (
    <div className="ingredients">
      <div className="section_header">
        <p>Ingredientes</p>
      </div>
      <div className="headings">
        <p>{item.Nome}</p>
        {
          halfItemName && <p>{halfItemName}</p>
        }
      </div>
      <div className="option_wrapper">
        <div className="options">
          {
            item.Ingredientes.map((ingredient) => (
              <label key={ingredient.Codigo}>
                <span>{ingredient.Nome}</span>
                <div className="custom_checkbox">
                  <input
                    type="checkbox"
                    onChange={() => setIngredient(ingredient.Codigo)}
                    checked={Boolean(ingredientList.find((ingItem) => ingItem.Codigo === ingredient.Codigo)?.Checked)}
                  />
                  <span className="checkmark" />
                </div>
              </label>
            ))
          }
        </div>
        {
          halfItemIngredients && (
            <div className="options">
              {
                halfItemIngredients.map((ingredient) => (
                  <label key={ingredient.Codigo}>
                      <span>{ingredient.Nome}</span>
                      <div className="custom_checkbox">
                        <input
                          type="checkbox"
                          onChange={() => setHalfIngredient(ingredient.Codigo)}
                          checked={isHalfIngredientChecked(ingredient.Codigo)}
                        />
                        <span className="checkmark" />
                      </div>
                    </label>
                ))
              }
            </div>
          )
        }
      </div>
    </div>
  )
}

export default Ingredients

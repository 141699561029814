import Field, { SpecificFieldProps } from '../../../components/Field'
import rotationLock from '../../../assets/icons/rotation-lock.png'

type SendEmailProps = {
  sendEmailProps: {
    emailFieldProps: SpecificFieldProps,
    handleSendEmail: () => void
  }
}

function SendEmail({ sendEmailProps }: SendEmailProps) {
  const { emailFieldProps, handleSendEmail } = sendEmailProps

  return (
    <>
      <div className="image_wrapper">
        <img src={rotationLock} alt="Rota bloqueada" />
      </div>
      <p className="label">Informe seu e-mail para enviarmos um código de recuperação de sua senha.</p>
      <Field fieldProps={emailFieldProps} />
      <button
        type="button"
        onClick={() => handleSendEmail()}
        className="main_button"
      >Enviar e-mail</button>
    </>
  )
}
export default SendEmail

import styled from 'styled-components'

export const RequestsContainer = styled.div`
  display: flex;
  flex-direction: column;

  h2 {
    color: #464646;
    margin-bottom: 1rem;
  }

  .items {
    width: 90%;
    margin: 0 auto;

    .item_list {
      display: flex;
      flex-direction: column;

      margin: 0 auto;
    }

    h2 {
      width: 90%;
      text-align: left;
    }
  }

  .stores {
    width: 90%;
    
    margin: 0 auto 2rem;

    .store_listing {
      white-space: nowrap;

      overflow-x: auto;
    }
  }

  @media (min-width: 646px) {
    .items {
      .item_list {
        flex-direction: row;
        flex-wrap: wrap;
      }
    }
  }
`

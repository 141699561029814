import { ChangeEvent, Ref } from 'react'
import { ErrorType } from '../../../hooks/useFormError'
import otp from '../../../assets/icons/otp.png'

type ConfirmationCodeProps = {
  confirmationCodeProps: {
    firstRef: Ref<HTMLInputElement>,
    secondRef: Ref<HTMLInputElement>,
    thirdRef: Ref<HTMLInputElement>,
    fourthRef: Ref<HTMLInputElement>,
    codeError: ErrorType | undefined,
    codeValue: string[],
    clearCode: () => void,
    validateCode: () => void,
    handleSendEmail: (arg?: boolean) => void,
    handleCodeFieldChange: (e: ChangeEvent<HTMLInputElement>) => void
  }
}

function ConfirmationCode({ confirmationCodeProps }: ConfirmationCodeProps) {
  const {
    firstRef,
    secondRef,
    thirdRef,
    fourthRef,
    codeError,
    codeValue,
    clearCode,
    validateCode,
    handleSendEmail,
    handleCodeFieldChange
  } = confirmationCodeProps

  return (
    <>
      <div className="image_wrapper">
                <img src={otp} alt="Código de telefone" />
              </div>
              <p className="label">Você receberá um e-mail com um código de validação em instantes.</p>
              <div className="fields">
                <div className={`input_field_wrapper ${codeError ? 'error' : undefined}`}>
                  <input ref={firstRef} type="text" id="f1" value={codeValue[0]} onChange={handleCodeFieldChange} />
                </div>
                <div className={`input_field_wrapper ${codeError ? 'error' : undefined}`}>
                  <input ref={secondRef} type="text" id="f2" value={codeValue[1]} onChange={handleCodeFieldChange} />
                </div>
                <div className={`input_field_wrapper ${codeError ? 'error' : undefined}`}>
                  <input ref={thirdRef} type="text" id="f3" value={codeValue[2]} onChange={handleCodeFieldChange} />
                </div>
                <div className={`input_field_wrapper ${codeError ? 'error' : undefined}`}>
                  <input ref={fourthRef} type="text" id="f4" value={codeValue[3]} onChange={handleCodeFieldChange} />
                </div>
              </div>
              {
                codeError && (
                  <span className="error">{codeError?.message}</span>
                )
              }
              <button
                type="button"
                onClick={validateCode}
                className="main_button"
              >
                Confirmar código
              </button>
              <div className="actions">
                <p>
                  Não recebi o Código!
                  <button type="button" onClick={() => handleSendEmail(true)}>Reenviar</button>
                </p>
                <button type="button" onClick={clearCode}>
                  Limpar
                </button>
              </div>
    </>
  )
}
export default ConfirmationCode

import { requestIcons } from '../assets/icons'

export function getRequestInfo (requestType: string): string[] {
  switch (requestType.toUpperCase()) {
    case 'CONFIRMED':
      return [requestIcons.confirm, 'Confirmado']

    case 'CANCELLED':
      return [requestIcons.prohibition, 'Cancelado']

    case 'PREPARATION_STARTED':
      return [requestIcons.cooking, 'Preparando']

    case 'DISPATCHED':
      return [requestIcons.delivery, 'Despachado']

    case 'READ_TO_PICKUP':
      return [requestIcons.takeAway, 'Pronto para retirada']

    case 'CONCLUDED':
      return [requestIcons.flag, 'Concluído']

    default:
      return [requestIcons.clock, 'Aguardando']
  }
}

import ReactDOM from 'react-dom'

import { X } from 'lucide-react'
import Overlay from '../../../components/Overlay'
import { ImageModalContainer } from '../styles'

type ImageModalProps = {
  close: () => void,
  image: string
}

function ImageModal ({ close, image }: ImageModalProps) {
  const itemImagePortal = document.getElementById('item_image_portal')

  if (!itemImagePortal) return null

  return ReactDOM.createPortal((
    <Overlay onClick={close}>
      <ImageModalContainer>
        <header>
          <button type="button" onClick={close}>
            <X />
          </button>
        </header>
        <div className="image_wrapper">
          <img src={image} alt="Imagem do Item" />
        </div>
      </ImageModalContainer>
    </Overlay>
  ), itemImagePortal)
}

export default ImageModal

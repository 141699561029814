import styled from 'styled-components'

type PDHeaderItemProps = {
  background: string
}

export const SizeSelectorContainer = styled.div`
  button {
    background: #ffffff;
    border: 1px solid #ffffff;
    border-radius: 1.6rem;
    box-shadow: 5px 5px 15px -7px rgba(0,0,0,0.11);

    padding: 1.2rem;
    margin: 0 1.6rem 1.2rem 0;

    span {
      font-weight: 700;
    }

    &.active {
      border: 1px solid #ff0000;
      box-shadow: 5px 5px 15px -7px rgba(0,0,0,0.19);
    }
  }

  .icon {
    width: 8rem;

    img {
      width: 100%;
    }
  }

  .more_options {
    display: flex;

    margin-top: 2rem;

    button {
      flex: 1;

      font-weight: 700;
    }
  }
`

export const FooterContainer = styled.footer`
  background-color: #ffffff;

  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;

  border-top: 1px solid #555555;
  
  padding: 0 1.4rem;

  .price_summary {
    padding: 1rem 0;

    text-align: right;

    p {
      font-size: 1.4rem;
      font-weight: 700;
    }

    span {
      color: #ff0000;

      font-size: 1.5rem;
    }
  }

  .add_to_cart {
    display: flex;

    padding: .8rem 0 1.6rem;

    .counter {
      display: flex;
      align-items: center;

      margin-right: 2rem;

      span {
        font-size: 1.8rem;
        font-weight: 700;
      }

      button {
        background: none;

        display: flex;
        align-items: center;
        justify-content: center;

        padding: .6rem 1.4rem;
      }
    }

    .add_button {
      flex: 1;

      background: #ff0000;
      border-radius: .4rem;

      color: #ffffff;
      font-size: 1.3rem;
      font-weight: 700;

      padding: 1.4rem 0;

      &:disabled {
        background: #aaaaaa;
      }
    }
  }
`

export const ItemOptionsContainer = styled.section`
  padding: 1.2rem 1rem;

  .options {
    padding: 1.4rem 0;

    label {
      display: flex;
      justify-content: space-between;
      align-items: center;

      border-bottom: 1px solid #dddddd;

      padding: .8rem 0;

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  .section_header {
    background: #dddddd;

    display: flex;
    justify-content: space-between;

    padding: .8rem .4rem;

    p {
      color: #464646;

      font-size: 1.6rem;
      font-weight: 700;
    }

    span {
      color: #888888;
      font-size: 1.2rem;
    }

    .required {
      color: #ff0000;
      font-size: 1.4rem;
    }
  }

  .ingredients {
    .headings {
      display: flex;

      justify-content: space-around;

      p {
        flex: 1;

        color: #464646;
        text-align: center;

        font-size: 1.3rem;
        font-weight: 700;

        padding: 1.2rem 0;
      }
    }

    span {
      font-size: 1.2rem;
      font-weight: 700;
    }

    .option_wrapper {
      display: flex;

      .options {
        flex: 1;

        &:nth-child(2) {
          margin-left: 1.6rem;
        }
      }
    }
  }

  .additional {
    .additionals_wrapper {
      display: flex;
      justify-content: space-between;

      > div {
        flex: 1;

        &:first-child {
          margin-right: 1.2rem;
        }
      }

      .choose_additional_button {
        background: #ffffff;
        border: 1px solid #ff0000;
        border-radius: 1.6rem;
        color: #ff0000;

        padding: .8rem .6rem;
        margin: 1.2rem 0 1.4rem;

        font-size: 1.4rem;
        font-weight: 700;

        width: 100%;
      }
    }

    .headings {
      display: flex;

      margin-top: 1.2rem;

      p {
        flex: 1;

        color: #464646;
        font-size: 1.3rem;
        font-weight: 700;

        text-align: center;
      }
    }

    .additional_list {
      .additional_item {
        border: 1px solid #464646;
        border-radius: 1.6rem;

        display: flex;
        align-items: flex-start;

        padding: 1.2rem 1rem;
        margin-bottom: 1rem;

        div {
          flex: 1;

          .additional_name {
            font-size: 1.2rem;
            font-weight: 700;

            margin-bottom: .4rem;
          }

          .additional_pricing {
            color: #bbbbbb;

            font-size: 1.2rem;
            font-weight: 700;
          }
        }

        button {
          background: #ff0000;
          border-radius: .6rem;

          display: flex;
          justify-content: center;
          align-items: center;

          padding: .2rem 1rem;
          margin-left: .6rem;
        }

        &:last-child {
          margin-bottom: 2rem;
        }
      }
    }
  }

  .observations {
    margin-bottom: 10rem;

    textarea {
      width: 100%;

      resize: vertical;

      padding: 1.6rem 1rem;
      margin: 2rem 0;
    }

    button {
      background: 0;
      color: #464646;
      font-weight: 700;
    }
  }
`

export const PDHeader = styled.header`
  .pdh_container {
    position: relative;

    width: 100%;

    padding: 1rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    z-index: 600;
  } 

  .item_description {
    position: relative;

    overflow-y: hidden;

    h2, p {
      cursor: pointer;
    }

    .desc_group {
      position: relative;
      z-index: 600;

      display: flex;

      margin: 4rem auto 2rem;

      > div {
        flex: 1;

        &:nth-child(2) {
          padding-left: 4rem;
        }
      }
    }

    .items {
      display: flex;

      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .item {
        flex: 1;

        padding: 12rem 1rem 1rem 1rem;
      }
    }
  }

  .clear {
    clear: both;
  }

  .remove_option_btn {
    background: #880000;
    border-radius: .6rem;
    color: #ffffff;

    font-size: 1.2rem;
    font-weight: 700;

    padding: .6rem 1.2rem;
  }

  h2, p {
    color: #ffffff;
    text-shadow: 2px 2px 4px #00000088;
  }

  h2 {
    font-size: 2rem;
    margin-bottom: .8rem;
  }

  p {
    font-size: 1.4rem;
    font-weight: bold;
  }

  button {
    background: none;

    position: relative;

    z-index: 6000;

    svg {
      filter: drop-shadow(1px 1px 3px #00000088);
    }
  }
`

export const PDHeaderItemBox = styled.div<PDHeaderItemProps>`
  background: url(${({ background }) => background});
  background-position: center;
  background-size: cover;

  cursor: pointer;

  .item_image {
    display: none;
  }
`

export const ImageModalContainer = styled.div`
  background: #ffffff;
  border-radius: 1rem;

  width: min(90vw, 350px);

  position: relative;

  padding: 1rem 1.2rem;

  z-index: 6000;

  header {
    margin-bottom: 1rem;
  }

  .image_wrapper {
    width: 100%;

    img {
      border-radius: .8rem;

      width: 100%;
    }
  }
`

import { Minus, Plus } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { setBackAddress } from '../../../store/features/item/itemSlice'
import { RootState } from '../../../store/store'
import { FooterContainer } from '../styles'

export type FooterProps = {
  info: {
    quant: number,
    additionalsValue: number,
    handleDecreaseQuant: () => void,
    handleIncreaseQuant: () => void,
    totalPrice: number,
    condition: boolean,
    action: () => void
  },
  itemCode: number
}

function Footer ({ info, itemCode }: FooterProps) {
  const { quant, additionalsValue, handleDecreaseQuant, handleIncreaseQuant, totalPrice, condition, action } = info
  const { accessToken } = useSelector((state: RootState) => state.user)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleAddButton () {
    if (!accessToken) {
      dispatch(setBackAddress(itemCode))
      return navigate(`/login${search}`)
    }

    action()
  }

  return (
    <FooterContainer className="page_context">
        <div className="price_summary">
          <p>adicionais, etc... <span>+ R$ {additionalsValue.toFixed(2)}</span></p>
        </div>
        <div className="add_to_cart">
          <div className="counter">
            <button type="button" onClick={handleDecreaseQuant}>
              <Minus size={24} color="#990000" />
            </button>
            <span>{quant}</span>
            <button type="button" onClick={handleIncreaseQuant}>
              <Plus size={24} color="#439A00" />
            </button>
          </div>
          <button className="add_button" onClick={handleAddButton} disabled={!condition}>
            Adicionar: R$ {totalPrice.toFixed(2)}
          </button>
        </div>
      </FooterContainer>
  )
}
export default Footer

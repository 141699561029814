import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Additional, Addon, cardapioItem, Size } from '../../../types/cardapioItem'
import { itemService } from './itemService'

const initialState = {
  item: {} as cardapioItem,
  halfItem: undefined as cardapioItem | undefined,
  initialSizes: [] as Size[],
  backToItem: 0,
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: ''
}

export type getItemsProps = {
  type: string,
  code: string | number,
  token: string,
  domain: string
}

type SetItemAdditionalProps = {
  sizeCode: number,
  additional: Additional
}

type SetComplementProps = {
  type: string,
  complement: Addon,
  action?: 'plus' | 'minus',
  sizeCode: number,
  border: boolean,
  pasta: boolean
}

type ResetBordersProps = {
  sizeCode: number
}

export const getItem = createAsyncThunk(
  'item/get',
  async (props: getItemsProps, thunkApi) => {
    try {
      const response = await itemService.getItem(props.type, props.code, props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const getHalfItem = createAsyncThunk(
  'item/get_half',
  async (props: getItemsProps, thunkApi) => {
    try {
      const response = await itemService.getItem(props.type, props.code, props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    setBackAddress: (state, action: PayloadAction<number>) => {
      state.backToItem = action.payload
    },
    cleanBackAddress: (state) => {
      state.backToItem = 0
    },
    clearItem: (state) => {
      state.item = {} as cardapioItem
    },
    clearHalfItem: (state) => {
      state.halfItem = undefined
    },
    setItemComplement: (state, action: PayloadAction<SetComplementProps>) => {
      const { action: act, complement, type, sizeCode, border, pasta } = action.payload

      if (border) {
        state.item = {
          ...state.item,
          Borda: {
            ...complement,
            Quantidade: 1
          }
        }

        return
      }

      if (pasta) {
        state.item = {
          ...state.item,
          TipoMassa: {
            ...complement,
            Quantidade: 1
          }
        }

        return
      }

      if (act === 'plus') {
        const currentSize = state.item.Tamanhos.find((size) => size.Codigo === sizeCode)
        const currentComplement = currentSize?.Complementos.find((complement) => complement.Nome === type)

        if (!currentComplement) return

        const currentComplementSum = currentComplement.Itens.reduce((prev, curr) => prev + curr.Quantidade, 0)

        if (currentComplementSum + 1 > currentComplement.QtdeMaxima) return

        state.item = {
          ...state.item,
          Tamanhos: state.item.Tamanhos.map((size) => (
            size.Codigo !== sizeCode
              ? size
              : {
                  ...size,
                  Complementos: size.Complementos.map((comp) => (
                    comp.Nome !== type
                      ? comp
                      : {
                          ...comp,
                          Itens: comp.Itens.map((item) => (
                            (item.Nome === complement.Nome)
                            ? {
                              ...item,
                              Quantidade: item.Quantidade + 1
                            }
                            : item
                          ))
                        }
                  ))
                }
          ))
        }

        return
      }

      if (act === 'minus') {
        state.item = {
          ...state.item,
          Tamanhos: state.item.Tamanhos.map((size) => (
            size.Codigo !== sizeCode
              ? size
              : {
                  ...size,
                  Complementos: size.Complementos.map((comp) => (
                    comp.Nome !== type
                      ? comp
                      : {
                          ...comp,
                          Itens: comp.Itens.map((item) => (
                            (item.Nome === complement.Nome)
                            ? {
                              ...item,
                              Quantidade: item.Quantidade - 1
                            }
                            : item
                          ))
                        }
                  ))
                }
          ))
        }

        return
      }

      state.item = {
        ...state.item,
        Tamanhos: state.item.Tamanhos.map((size) => (
          size.Codigo !== sizeCode
            ? size
            : {
                ...size,
                Complementos: size.Complementos.map((comp) => (
                  comp.Nome !== type
                    ? comp
                    : {
                        ...comp,
                        Itens: comp.Itens.map((item) => (
                          (item.Nome === complement.Nome) && item.Quantidade === 0
                            ? {
                                ...item,
                                Quantidade: 1
                              }
                            : {
                                ...item,
                                Quantidade: 0
                              }
                        ))
                      }
                ))
              }
        ))
      }
    },
    toggleItemIngredient: (state, action: PayloadAction<number>) => {
      state.item = {
        ...state.item,
        Ingredientes: state.item.Ingredientes.map((ingredient) => (
          ingredient.Codigo !== action.payload
            ? ingredient
            : {
                ...ingredient,
                Checked: !ingredient.Checked
              }
        ))
      }
    },
    toggleHalfItemIngredient: (state, action: PayloadAction<number>) => {
      if (!state.halfItem) return

      state.halfItem = {
        ...state.halfItem,
        Ingredientes: state.halfItem.Ingredientes.map((ingredient) => (
          ingredient.Codigo !== action.payload
            ? ingredient
            : {
                ...ingredient,
                Checked: !ingredient.Checked
              }
        ))
      }
    },
    toggleItemAdditional: (state, action: PayloadAction<SetItemAdditionalProps>) => {
      state.item = {
        ...state.item,
        Tamanhos: state.item.Tamanhos.map((size) => (
          size.Codigo !== action.payload.sizeCode
            ? size
            : {
                ...size,
                Adicionais: size.Adicionais.map((add) => (
                  add.Descricao !== action.payload.additional.Descricao
                    ? add
                    : {
                        ...add,
                        Checked: !add.Checked
                      }
                ))
              }
        ))
      }
    },
    toggleHalfItemAdditional: (state, action: PayloadAction<SetItemAdditionalProps>) => {
      if (!state.halfItem) return

      state.halfItem = {
        ...state.halfItem,
        Tamanhos: state.halfItem.Tamanhos.map((size) => (
          size.Codigo !== action.payload.sizeCode
            ? size
            : {
                ...size,
                Adicionais: size.Adicionais.map((add) => (
                  add.Descricao !== action.payload.additional.Descricao
                    ? add
                    : {
                        ...add,
                        Checked: !add.Checked
                      }
                ))
              }
        ))
      }
    },
    resetBorders: (state, action: PayloadAction<ResetBordersProps>) => {
      state.item = {
        ...state.item,
        Tamanhos: state.item.Tamanhos.map((size) => (
          size.Codigo !== action.payload.sizeCode
            ? size
            : {
                ...size,
                Complementos: size.Complementos.map((complement) => (
                  !(complement.Origem === 'B' && complement.TipoItem === 'R')
                    ? complement
                    : {
                        ...complement,
                        Itens: complement.Itens.map((item) => ({
                          ...item,
                          Quantidade: 0
                        }))
                      }
                ))
              }
        ))
      }
    },
    resetSize: (state) => {
      state.item = {
        ...state.item,
        Tamanhos: state.initialSizes
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.item = action.payload
        state.initialSizes = action.payload?.Tamanhos
      })
      .addCase(getItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
      .addCase(getHalfItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getHalfItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.halfItem = action.payload
      })
      .addCase(getHalfItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
  }
})

export const {
  reset,
  setBackAddress,
  cleanBackAddress,
  clearItem,
  clearHalfItem,
  setItemComplement,
  toggleItemIngredient,
  toggleHalfItemIngredient,
  toggleItemAdditional,
  toggleHalfItemAdditional,
  resetBorders,
  resetSize
} = itemSlice.actions

export default itemSlice.reducer

import { ReactElement, ReactNode } from 'react'
import styled from 'styled-components'

type SimpleCardProps = {
  Image?: ReactElement,
  children: ReactNode,
  noFloatingIcon?: boolean
}

const SimpleCardContainer = styled.div`
  width: 100%;

  padding: 0 .8rem;

  .card_wrapper {
    border: 1px solid red;
    color: #888888;

    display: flex;
    justify-content: center;
    align-items: center;

    position: relative;

    width: 100%;
    height: 100%;

    padding: 1.6rem .4rem;
  }

  .icon {
    position: absolute;
    top: 50%;
    left: 2rem;
    transform: translateY(-50%);

    width: 2.6rem;

    img {
      width: 100%;
    }
  }

  span {
    color: #121212;
    font-size: 1.2rem;

    h3 {
      font-size: 1.3rem;
      font-weight: 500;
    }
  }

  &.block_icon {
    .icon {
      position: initial;
      top: 0;
      left: 0;
      transform: none;

      margin-left: 1.2rem;

      width: 4rem;
    }

    span {
      margin-left: 1.6rem;
    }
  }
`

function SimpleCard ({ Image, children, noFloatingIcon }: SimpleCardProps) {
  return (
   <SimpleCardContainer className={noFloatingIcon ? 'block_icon' : undefined}>
     <div className="card_wrapper">
      {
        Image && (
          <div className="icon">
            { Image }
          </div>
        )
      }
      { children }
     </div>
   </SimpleCardContainer>
  )
}
export default SimpleCard

import { ChevronLeft } from 'lucide-react'
import { ReactNode, useState } from 'react'

import { LayoutContainer } from './styles'
import IconeAgileDelivery from '../../assets/icons/icone-agile-branco.png'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'

type LayoutProps = {
  children: ReactNode,
  goBack?: () => void,
  hideItemStructure?: boolean,
  noGoBack?: boolean
}

function AgilePageLayout ({ children, goBack, hideItemStructure, noGoBack }: LayoutProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  const [imageError, setImageError] = useState<boolean>(false)

  return (
    <LayoutContainer background={storeParams.bgColor ? `#${storeParams.bgColor}` : '#503E97'} hideItemStructure={hideItemStructure}>
      <header className="page_context">
        {
          !noGoBack && (
            <button type="button" onClick={goBack}>
              <ChevronLeft size={36} color="#ffffff" />
            </button>
          )
        }
      </header>
      <div className="container small_context">
        <div className="logo">
          {
            !imageError && (
              <img src={storeParams.logo} onError={() => setImageError(true)} />
            )
          }
          {
            imageError && (
              <img src={IconeAgileDelivery} alt="Ícone Agile Delivery" />
            )
          }
        </div>
        {children}
      </div>
    </LayoutContainer>
  )
}

export default AgilePageLayout

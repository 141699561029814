import { ChevronDown, MapPin } from 'lucide-react'
import styled from 'styled-components'
import { AddressType } from '../../../types/address'

type AddressButtonProps = {
  handleAddressSelector: () => void,
  selectedAddress: AddressType
}

const AddressButtonContainer = styled.div`
  width: 100%;

  padding: .8rem;

  margin-top: 1.2rem;

  button {
    border: 1px solid #ff0000;

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    padding: .8rem 2rem 0;

    h3 {
      margin-bottom: 1rem;
    }

    div {
      display: flex;
      align-items: center;

      margin-bottom: 1rem;
    }

    svg:first-child {
      margin-right: .8rem;
    }

    svg:last-child {
      margin-left: .8rem;
    }
  }
`

function AddressButton ({ handleAddressSelector, selectedAddress }: AddressButtonProps) {
  return (
    <AddressButtonContainer>
      <button
        type="button"
        onClick={handleAddressSelector}
        className="address_selector_button"
      >
        <h3>ENDEREÇO DE ENTREGA</h3>
        <div>
          <MapPin size={20} />
          <span>{selectedAddress.Log.toUpperCase()}, {selectedAddress.Numero}</span>
          <ChevronDown size={20} />
        </div>
      </button>
    </AddressButtonContainer>
  )
}

export default AddressButton

import { Addon, Addons } from '../../../../types/cardapioItem'
import { ComplementContainerGroup } from './styles'

import { getComplementImage } from '../../../../utils/getComplementImage'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { useEffect } from 'react'

type SpecialComplementProps = {
  title: string,
  addon: Addons,
  setComplement: (name: string, arg: Addon, action: 'plus' | 'minus' | '', pasta: boolean, border: boolean) => void
}

function SpecialComplement ({ title, addon, setComplement }: SpecialComplementProps) {
  const { item } = useSelector((state: RootState) => state.item)

  function handleButton (itemCode: number, complement: Addon) {
    let booleanValues: boolean[] = []

    if (title === 'Massas') {
      booleanValues = [false, true]
    } else {
      booleanValues = [true, false]
    }

    setComplement(
      addon.Nome,
      complement,
      '',
      booleanValues[0],
      booleanValues[1]
    )
  }

  const currentObject = title === 'Massas' ? item.TipoMassa : item.Borda

  useEffect(() => {
    if (title !== 'Massas') return

    setComplement(
      addon.Nome,
      addon.Itens[0],
      '',
      false,
      true
    )
  }, [addon])

  if (title === 'Bordas' && item.TipoMassa?.AceitaBorda === 'N') return null

  return (
    <ComplementContainerGroup>
      <div className="section_header">
        <p>{title}</p>
      </div>
      <div className="special_options">
        {
          addon.Itens.map((complement) => (
            <button
              key={complement.Codigo}
              className={currentObject?.Nome === complement.Nome ? 'selected' : undefined}
              onClick={() => handleButton(complement.Codigo, complement)}
            >
              <p>R$ {complement.Preco.toFixed(2)}</p>
              <img src={getComplementImage(complement.Nome.toUpperCase())} />
              <p>{complement.Nome}</p>
            </button>
          )
          )
          }
      </div>
    </ComplementContainerGroup>
  )
}
export default SpecialComplement

import { ArrowLeft } from 'lucide-react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import ItemOptions from './components/ItemOptions'
import { clearHalfItem, getHalfItem, getItem, reset } from '../../store/features/item/itemSlice'
import { RootState } from '../../store/store'
import { PDHeader } from './styles'
import { ItemCardapio } from '../../types/cardapio'
import OverlayNotification from '../../components/OverlayNotification'
import PDHeaderItem from './components/PDHeaderItem'
import ImageModal from './components/ImageModal'

function ProductDetails () {
  const { tokenData, storeParams } = useSelector((state: RootState) => state.token)
  const { type } = useSelector((state: RootState) => state.cardapio)
  const { item, halfItem, isLoading, isError } = useSelector((state: RootState) => state.item)

  const [itemNotFound, setItemNotFound] = useState<boolean>(false)

  const [imageModal, setImageModal] = useState<string>('')

  const { search } = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const { codigoProduto } = useParams()

  useEffect(() => {
    if (!codigoProduto || !tokenData.access_token || !type || !storeParams.api) return

    const getItemArgs = {
      type: type,
      token: tokenData.access_token,
      code: codigoProduto,
      domain: storeParams.api
    }

    dispatch(getItem(getItemArgs))
  }, [codigoProduto, tokenData.access_token, type, storeParams.api])

  useEffect(() => {
    if (!isError) return

    setItemNotFound(true)
  }, [isError])

  function handleGoBackButton () {
    navigate(`/loja/cardapio${search}`)
  }

  function changeHalfItem (item: ItemCardapio | undefined) {
    if (!item) {
      return dispatch(clearHalfItem())
    }

    const getHalfItemProps = {
      type,
      code: item.Codigo,
      token: tokenData.access_token,
      domain: storeParams.api
    }

    dispatch(getHalfItem(getHalfItemProps))
  }

  function removeHalfItem () {
    dispatch(clearHalfItem())
  }

  function closeErrorModal () {
    setItemNotFound(false)

    dispatch(reset())

    navigate(`/loja/cardapio${search}`)
  }

  useEffect(() => {
    console.log(imageModal)
  }, [imageModal])

  useEffect(()=> {
    if (!type) handleGoBackButton();
  }, [type])
  
  return (
    <div>
      <PDHeader>
        <div className="item_description">
          <div className="pdh_container page_context">
            <button onClick={handleGoBackButton}>
              <ArrowLeft size={24} color="#ffffff" />
            </button>
            {
              halfItem && (
                <button className="remove_option_btn" onClick={removeHalfItem}>
                  Trocar
                </button>
              )
            }
          </div>
          <div className="desc_group page_context">
            <div
              className="item_description"
              onClick={() => setImageModal(`${storeParams.item}/${codigoProduto}.jpg`)}
            >
              <h2>{!isLoading && item?.Nome}</h2>
              <p>{!isLoading && item?.ReceitaCurta}</p>
            </div>
            {
              halfItem && (
                <div
                  className="item_description"
                  onClick={() => setImageModal(`${storeParams.item}/${halfItem.Codigo}.jpg`)}
                >
                  <h2>{halfItem?.Nome}</h2>
                  <p>{halfItem?.ReceitaCurta}</p>
                </div>
              )
            }
          </div>
          <div className="items">
            <PDHeaderItem
              background={`${storeParams.item}/${codigoProduto}.jpg`}
              action={(image: string) => setImageModal(image)}
            />
            {
              halfItem && (
                <PDHeaderItem
                  background={`${storeParams.item}/${halfItem.Codigo}.jpg`}
                  action={(image: string) => setImageModal(image)}
                />
              )
            }
          </div>
        </div>
      </PDHeader>
      {
        imageModal && (
          <ImageModal
            close={() => setImageModal('')}
            image={imageModal}
          />
        )
      }
      {
        item?.Tamanhos && (
          <ItemOptions
            setHalf={changeHalfItem}
            codigo={Number(codigoProduto)}
          />
        )
      }
      {
        itemNotFound && (
          <OverlayNotification
            text="Algum erro ocorreu na busca pelo item, tente novamente mais tarde."
            close={closeErrorModal}
          />
        )
      }
    </div>
  )
}

export default ProductDetails

import { ChangeEvent, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import SearchBar from '../../components/SearchBar'
import { RootState } from '../../store/store'
import { ItemCardapio } from '../../types/cardapio'
import Results from './components/Results'
import { SearchProductsContainer } from './styles'

function SearchProducts () {
  const [searchValue, setSearchValue] = useState<string>('')
  const [warning, setWarning] = useState<string>('')

  const { cardapio, categories } = useSelector((state: RootState) => state.cardapio)

  let cardapioResults: ItemCardapio[] = []

  const { search } = useLocation()

  const navigate = useNavigate()

  function handleChangeSearchValue (e: ChangeEvent<HTMLInputElement>) {
    setSearchValue(e.target.value)
  }

  function handleGoBack () {
    navigate(`/loja/cardapio${search}`)
  }

  cardapioResults = cardapio.filter((item) => item.Search.includes(searchValue.toLowerCase()))

  useEffect(() => {
    if (searchValue.length < 4) {
      setWarning('Para realizar a pesquisa o texto tem que ter mais que 4 caracteres.')
    } else if (cardapioResults.length === 0) {
      setWarning('Nenhum item encontrado')
    } else {
      setWarning('')
    }
  }, [searchValue.length, cardapioResults.length])

  return (
    <SearchProductsContainer>
      <SearchBar
        searchValue={searchValue}
        handleGoBack={handleGoBack}
        handleChangeSearchValue={handleChangeSearchValue}
        cleanSearchValue={() => setSearchValue('')}
        placeholder="Buscar itens, ingredientes, etc..."
      />
      {
        searchValue.length >= 4 && !warning
          ? <Results results={cardapioResults} categories={categories} sendWarning={setWarning} />
          : (
          <div className="search_condition_view page_context">
            <p>{warning}</p>
          </div>
            )
      }
    </SearchProductsContainer>
  )
}

export default SearchProducts

import { Building, LogIn, X } from 'lucide-react'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import AgilePageLayout from '../../components/AgilePageLayout'
import OverlayNotification from '../../components/OverlayNotification'
import ScaleLoader from '../../components/ScaleLoader'
import { setType } from '../../store/features/cardapio/cardapioSlice'
import { RootState } from '../../store/store'
import getRelativeLuminance from '../../utils/getRelativeLuminance'
import { cepMask } from '../../utils/masks'
import AddressForm from './components/AddressForm'
import { dneInstance } from './dneInstance'
import { AddressContainer } from './styles'

const initialLocationState = {
  cep: '',
  uf: '',
  cid: {
    c: 0,
    d: ''
  },
  log: null
}

export type Location = {
  c: number,
  d: string
}

export type LocationType = {
  cep: string,
  uf: string,
  cid: Location,
  log: {
    bai: Location,
    c: number,
    d: string
  } | null
}

function Address () {
  const [cep, setCep] = useState<string>('')
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false)
  const [selectedCep, setSelectedCep] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [location, setLocation] = useState<LocationType>(initialLocationState)
  const [isAddressFormOpen, setIsAddressFormOpen] = useState<boolean>(false)
  const [error, setError] = useState<string>('')

  const { accessToken } = useSelector((state: RootState) => state.user)
  const { storeParams } = useSelector((state: RootState) => state.token)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleGoBack () {
    if (isAddressFormOpen) {
      setLocation(initialLocationState)
      return setIsAddressFormOpen(false)
    } else {
      dispatch(setType('retirada'))
      return navigate(-1)
    }
  }

  useEffect(() => {
    if (!selectedCep) return

    setIsLoading(true);

    (async () => {
      try {
        const response = await dneInstance({
          url: `/CEP/Get?cep=${selectedCep}`
        })

        setIsLoading(false)
        setLocation(response.data)
        setIsAddressFormOpen(true)
      } catch (err: any) {
        setIsLoading(false)
        setError(err.response.data.Messages[0])
      }
    })()

    setSelectedCep('')
  }, [selectedCep])

  const CEPButtonTextColor = useMemo(() => {
    return getRelativeLuminance(storeParams.bgColor) > 0.5 ? 'black_text' : 'white_text'
  }, [])

  return (
    <AgilePageLayout
      goBack={handleGoBack}
      hideItemStructure={!isAddressFormOpen}
    >
      {
        isAddressFormOpen
          ? (
              <AddressForm location={location} />
            )
          : (
              <AddressContainer>
                <div className="select_address_box">
                  <label className={isInputFocused ? 'focus' : undefined}>
                    <div>
                      <div className="icon">
                        <Building size={24} />
                      </div>
                      <input
                        type="text"
                        placeholder="Digite seu cep..."
                        value={cep}
                        onChange={(e) => setCep(cepMask(e.target.value))}
                        onFocus={() => setIsInputFocused(true)}
                        onBlur={() => setIsInputFocused(false)}
                      />

                      <button
                        type="button"
                        className="clear_button"
                        onClick={() => setCep('')}
                      >
                        <X color="#aaaaaa" size={24} />
                      </button>
                    </div>

                    <button
                      type="button"
                      className="follow_button"
                      onClick={() => setSelectedCep(cep.replace(/[\D]/g, ''))}
                    >
                      Seguir
                    </button>
                  </label>

                  <div className="btn_wrapper">
                    <button
                      type="button"
                      className={`help_cep ${CEPButtonTextColor}`}
                      onClick={() => setIsAddressFormOpen(true)}
                    >Não sei o meu CEP</button>
                  </div>
                </div>
                {
                  !accessToken && (
                    <button
                      className="login_button"
                      onClick={() => navigate(`/login${search}`)}
                    >
                      <LogIn size={24} />
                      <span>Fazer login</span>
                    </button>
                  )
                }
              </AddressContainer>
            )
      }
      {
        error && <OverlayNotification text={error} close={() => setError('')} />
      }
      {
        isLoading && <ScaleLoader />
      }
    </AgilePageLayout>
  )
}

export default Address

import { useState } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { ItemCardapio } from '../../types/cardapio'
import { capitalizeEachWord } from '../../utils/capitalizeEachWord'
import { CategoryItemWrapper } from './styles'

import itemImageError from '../../assets/icons/image-error.png'
import redxis from '../../assets/icons/redxis.png';
import PriceCard from '../PriceCard/Index'

type CategoryItemCardProps = {
  item: ItemCardapio,
  navigateToItem: (code: number) => void,
  chefSuggestion: boolean
}

function CategoryItemCard(
  {
    item,
    navigateToItem,
    chefSuggestion
  }: CategoryItemCardProps) {

  const { Codigo, Nome,  ReceitaCurta, PrecoReferencia, ValorComDesconto, Preco } = item;
  
  const isPromo = PrecoReferencia > ValorComDesconto;

  const { storeParams } = useSelector((state: RootState) => state.token)

  const [imageError, setImageError] = useState(false)

  return (
    <CategoryItemWrapper onClick={() => navigateToItem(Codigo)} key={Codigo} chef={chefSuggestion}>
      <div className={`image ${imageError && 'image_with_error'}`}>
        <LazyLoadImage src={!imageError ? `${storeParams.item}/${Codigo}.jpg` : itemImageError} onError={() => setImageError(true)} />
      </div>
      <div className="info">
        <div className="names">
          <strong className="title">{capitalizeEachWord(Nome)}</strong>
          <p className="description">{ReceitaCurta}</p>
        </div>
       <PriceCard Preco={Preco} PrecoReferencia={PrecoReferencia}/>
      </div>
    </CategoryItemWrapper>
  )
}

export default CategoryItemCard

import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import { PropsParams, TokenRequestBody, TokenResponse } from '../../../types/authApi'
import { StoreParams } from '../../../types/storeInfo'
import { tokenService } from './tokenService'

const initialState = {
  tokenData: {} as TokenResponse,
  storeProps: {} as PropsParams,
  storeParams: {
    name: '',
    api: '',
    store: '',
    item: '',
    complement: '',
    background: '',
    bgColor: '',
    logo: ''
  },
  isSuccess: false,
  isSyncError: false,
  isError: false,
  isLoading: false,
  message: ''
}

export const getToken = createAsyncThunk(
  'token/get',
  async (body: TokenRequestBody, thunkApi) => {
    try {
      const response = await tokenService.getToken(body)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

const tokenSlice = createSlice({
  name: 'token',
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false
      state.isError = false
      state.isSyncError = false
      state.isLoading = false
      state.message = ''
    },
    define: (state, action: PayloadAction<PropsParams>) => {
      state.storeProps = action.payload
    },
    setUrl: (state) => {
      const definedProfile = current(state.tokenData).Perfis.find((profile) => profile.Ambiente === state.storeProps.environment)

      if (definedProfile) {
        state.storeParams = {
          ...state.storeParams,
          api: definedProfile.Url
        }
      } else {
        state.isSyncError = true
      }
    },
    setResourcesUri: (state, action: PayloadAction<StoreParams>) => {
      state.storeParams = {
        ...state.storeParams,
        name: action.payload.Nome,
        store: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_LOJA')?.Valor || '',
        item: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_ITEM')?.Valor || '',
        complement: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_COMPLEMENTO')?.Valor || '',
        background: action.payload.Parametros?.find((param) => param.Chave === 'MV_HOST_FOTO_CAPA')?.Valor || '',
        bgColor: action.payload.Parametros?.find((param) => param.Chave === 'MV_COLOR_BACKGROUND')?.Valor || '',
        logo: action.payload.LogoUri
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.tokenData = action.payload
      })
      .addCase(getToken.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
  }
})

export const { reset, define, setUrl, setResourcesUri } = tokenSlice.actions
export default tokenSlice.reducer

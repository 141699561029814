function phoneNumberMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d)/, '$1')
  )
}

function cardNumberMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/(\d{4})(\d)/, '$1 $2')
      .replace(/( \d{4})(\d+?)/, '$1')
  )
}

function cpfMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d)/, '$1-$2')
      .replace(/(-\d{2})(\d+?)/, '$1')
  )
}

function cepMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{3})(\d)/, '$1')
  )
}

function expiringDateMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '$1/$2')
      .replace(/(\/\d{2})(\d)/, '$1')
  )
}

function cvvMask (value: string | undefined) {
  if (!value) return ''

  return (
    value
      .replace(/[\D]/g, '')
      .replace(/(\d{3})(\d)/, '$1')
  )
}

export {
  phoneNumberMask,
  cardNumberMask,
  expiringDateMask,
  cpfMask,
  cepMask,
  cvvMask
}

import { X } from 'lucide-react'
import { ChangeEvent } from 'react'
import { FieldPattern } from '../styles'

type FAFieldProps = {
  info: {
    heading: string,
    placeholder: string,
    value: string,
    onChange: (e: ChangeEvent<HTMLInputElement>) => void,
    clear: () => void,
    name: string,
    onFocus: () => void,
    onBlur: () => void,
    focus: boolean,
    error?: string
  }
}

function FAField ({ info }: FAFieldProps) {
  const { heading, clear, focus, error, ...props } = info

  return (
    <>
      <FieldPattern className={focus ? 'focused' : undefined}>
        <div className="box">
          <label>
            <span className="heading">{heading}</span>
            <input {...props} />
            <button type="button" onClick={clear}>
              <X size={24} />
            </button>
          </label>
        </div>
        { error && <span className="error">{error}</span> }
      </FieldPattern>
    </>
  )
}

export default FAField

import { getSizeImage } from '../../../utils/getSizeImage'
import { Size } from '../../../types/cardapioItem'
import { SizeSelectorContainer } from '../styles'

type SizeSelectorProps = {
  item: Size[],
  chooseSize: (arg: number) => void,
  currentSize: Size,
  chooseProductType: (type: 'normal' | 'meio a meio') => void,
  productType: 'normal' | 'meio a meio',
  category: string
}

function SizeSelector ({ item, chooseSize, currentSize, chooseProductType, productType, category }:SizeSelectorProps) {
  return (
    <SizeSelectorContainer>
      <div className="section_header">
        <p>Tamanho</p>
      </div>
      <div className="options">
        <div className="sizes">
          {
            item && item.map((size, index) => (
              <button
                className={size.Nome === currentSize.Nome ? 'active' : undefined}
                key={size.Nome}
                onClick={() => chooseSize(index)}
              >
                <div className="icon">
                  <img src={getSizeImage(category)} alt="Imagem do tamanho do produto" />
                </div>
                <span>{size.Nome}</span>
              </button>
            ))
          }
        </div>
        {
          currentSize.PodeMeioMeio === 'S' && currentSize.ItensMeioMeio.length > 0 && (
            <div className="more_options">
              <button
                className={productType === 'normal' ? 'active' : undefined}
                onClick={() => chooseProductType('normal')}
              >
                Normal
              </button>

              <button
              className={productType === 'meio a meio' ? 'active' : undefined}
                onClick={() => chooseProductType('meio a meio')}
              >
                Meio a Meio
              </button>
            </div>
          )
        }
      </div>
    </SizeSelectorContainer>
  )
}
export default SizeSelector

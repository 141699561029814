import styled from 'styled-components';

export const SalesModesContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  > .heading {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: .5rem 0;

    > p {
        font-size: 1.5rem;
        color: #ea0e4b;
        font-weight: bold;
    }
  }

  > .card-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;

    > .card {
        display: flex;
        align-items: center;
        flex-direction: column;
        border: 1px solid #f2f2f2;
        padding: 1.2rem 0;
        border-radius: 10px;
        background-color:rgba(250, 250, 250, 0.8);
        box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

        > img {
            width: 50px;
            height: 50px;
        }

        > p {
            font-size: 16px;
            font-weight: bold;

        }
    }
  }
`;

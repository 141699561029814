import { useState } from 'react'

export type ErrorType = {
  message: string,
  field: string
}

type ReturnType = [
  (field: string, message: string) => void,
  (field: string) => ErrorType | undefined,
  (field: string) => void
]

export default function useFormError (): ReturnType {
  const [errors, setErrors] = useState<ErrorType[]>([])

  function cleanErrorsByFieldname (field: string) {
    setErrors((prevState) => prevState.filter((error) => error.field !== field))
  }

  function getErrorByFieldname (field: string) {
    return errors.find((error) => error.field === field)
  }

  function setNewError (field: string, message: string) {
    cleanErrorsByFieldname(field)

    setErrors((prevState) => ([
      ...prevState,
      {
        field, message
      }
    ]))
  }

  return [
    setNewError,
    getErrorByFieldname,
    cleanErrorsByFieldname
  ]
}

import { Search } from 'lucide-react'
import { MouseEvent } from 'react'
import { FieldPattern } from '../styles'

type PlaceButtonProps = {
  heading: string,
  placeholder: string,
  id: string,
  action: (e: MouseEvent<HTMLButtonElement>) => void,
  value: string,
  error?: string
}

function PlaceButton ({ heading, placeholder, id, action, value, error }: PlaceButtonProps) {
  return (
    <FieldPattern>
      <div className="box">
        <button type="button" id={id} onClick={action}>
          <span className="heading">{heading}</span>
          <input
            value={value}
            type="text"
            placeholder={placeholder}
            disabled
          />
          <Search size={24} />
        </button>
      </div>
      { error && <span className="error">{error}</span> }
    </FieldPattern>
  )
}

export default PlaceButton

import { Bike, Car } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../store/store'
import SimpleCard from './SimpleCard'

function Estimate () {
  const { info } = useSelector((state: RootState) => state.info)
  const { type } = useSelector((state: RootState) => state.cardapio)

  if (!info.TempoInicioEntrega || !info.TempoEntrega || !info.TempoRetirada) {
    return null
  }

  const minimalDeliveryTime = info.TempoInicioEntrega.split(':')[1]
  const deliveryTime = info.TempoEntrega.split(':')[1]
  const withdrawTime = info.TempoRetirada.split(':')[1]

  return (
    <SimpleCard
      Image={
        (type === 'entrega' || type === 'retirada')
          ? <Bike />
          : <Car />
      }
    >
      {
        (type !== 'drive' && type !== 'retirada')
          ? (
              <span><strong>Estimativa de entrega:</strong> {minimalDeliveryTime}min a {deliveryTime}min</span>
            )
          : (
              <span><strong>Estimativa de retirada:</strong> {withdrawTime}min</span>
            )
      }
    </SimpleCard>
  )
}

export default Estimate

/* eslint-disable multiline-ternary */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import Router from './components/Router';
import {
  define,
  getToken,
  setResourcesUri,
  setUrl
} from './store/features/token/tokenSlice';
import { getCardapio, setType } from './store/features/cardapio/cardapioSlice';
import { getStoreInfo, getStoreParams } from './store/features/info/infoSlice';
import { RootState } from './store/store';
import GlobalStyle from './styles/GlobalStyle';
import mock from './styles/themes/mock';
import {
  loginWithRefreshToken,
  setAddress,
  setHash,
  setOfflineAddress,
  setRefreshToken
} from './store/features/user/userSlice';
import useQuery from './hooks/useQuery';
import { decode } from 'js-base64';
import Error from './components/Error';
import ScaleLoader from './components/ScaleLoader';
import hashMock from './mocks/hashes.json';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';
import { InitiateFacebookTracking } from './monitoring/facebook'
import { TrackingValidator } from './monitoring/TrackingValidator';

function App() {
  const query = useQuery();
  const dispatch = useDispatch();

  const location = useLocation();

  const {
    tokenData,
    storeProps,
    storeParams,
    isLoading: isTokenLoading
  } = useSelector((state: RootState) => state.token);
  const {
    userData,
    selectedAddress,
    refreshToken,
    refreshed,
    hash,
    isLoading: isUserLoading
  } = useSelector((state: RootState) => state.user);
  const {
    info,
    params,
    isError,
    isLoading: isInfoLoading
  } = useSelector((state: RootState) => state.info);
  const { type, isLoading: isCardapioLoading } = useSelector(
    (state: RootState) => state.cardapio
  );
  const { isLoading: isItemLoading } = useSelector(
    (state: RootState) => state.item
  );

  const [noHashError, setNoHashError] = useState<boolean>(false);

  useEffect(() => {
    const loginRefreshToken = localStorage.getItem(`refresh_token_${hash}`);

    if (!loginRefreshToken) return;

    dispatch(setRefreshToken(loginRefreshToken));
  }, [refreshToken, hash]);

  useEffect(() => {
    if (
      !tokenData.access_token &&
      storeProps.userName &&
      storeProps.license &&
      storeProps.version
    ) {
      const getTokenParams = {
        userName: storeProps.userName,
        password: storeProps.license,
        versaoApi: storeProps.version
      };

      dispatch(getToken(getTokenParams));
    }

    if (tokenData.access_token && !storeParams.api) {
      dispatch(setUrl());
    }

    if (!userData.unique_name && !selectedAddress.Log) {
      const storedAddress = localStorage.getItem('offline_address');

      if (storedAddress) {
        dispatch(setOfflineAddress(JSON.parse(storedAddress)));
      }
    }

    if (refreshToken && storeParams.api) {
      const loginWithRefreshTokenProps = {
        token: refreshToken,
        domain: storeParams.api
      };

      dispatch(loginWithRefreshToken(loginWithRefreshTokenProps));
    }
  }, [tokenData.access_token, storeProps, storeParams.api, info.Nome]);

  // get menu and store info
  useEffect(() => {
    if (tokenData.access_token && storeParams.api) {
      const getCardapioParams = {
        option: type,
        token: tokenData.access_token,
        domain: storeParams.api
      };

      const getStoreInfoProps = {
        token: tokenData.access_token,
        domain: storeParams.api
      };

      dispatch(getCardapio(getCardapioParams));

      dispatch(getStoreInfo(getStoreInfoProps));
    }
  }, [tokenData.access_token, storeParams.api]);

  // get store params
  useEffect(() => {
    if (tokenData.access_token && storeParams.api && info.Nome) {
      const getStoreParamsProps = {
        token: tokenData.access_token,
        body: info.Endereco,
        domain: storeParams.api,
        storeId: info.Codigo
      };

      dispatch(getStoreParams(getStoreParamsProps));
    }
  }, [tokenData.access_token, storeParams.api, info.Nome, info.Endereco]);

  // define resources based on params
  useEffect(() => {
    if (!params.Parametros) return;

    dispatch(setResourcesUri(params));
  }, [params.Parametros]);

  // set addresses
  useEffect(() => {
    let selectedAddress;

    if (userData.unique_name) {
      selectedAddress = localStorage.getItem(
        `selected_address_${userData.unique_name}`
      );
    } else {
      selectedAddress = localStorage.getItem('selected_address');
    }

    dispatch(setAddress(selectedAddress));
  }, [userData.unique_name]);

  // get query params
  useEffect(() => {
    if (storeProps.license) return;

    // To be removed when deployed

    /**
     * userName@AgilePDV#lic@247273142#version@1.0#env@PRD#table@1
       userName@AgileCardapio#lic@247273142#version@1.0#env@PRD

     * B11 (https://burge11.agiledelivery.app.br/): 
     *  APP PRD:  dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNAODcyNDUxNDI1I3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBBUFA=
     *  SALAO PRD: dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNAODcyNDUxNDI1I3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBTQUxBTw==
     * 
     * RPR:
     *  PRD: 
     * 
     * MASSAS (https://bellacapri.agiledelivery.app.br/):
     *  APP PRD: 
     *  SALAO PRD: 
     * 
     * CRP (https://cervejariariopreto.agiledelivery.app.br/):
     *  APP PRD: dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNANTI4MjY3NTM0I3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBBUFA=
     *  SALAO PRD: dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNANTI4MjY3NTM0I3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBTQUxBTw==
     */

    //const param = query.get('z') || 'bGljQDE5MjY0NzM2NDUjdmVyc2lvbkAxLjAjZW52QEhNTA==' //HML
    //const param = query.get('z') || 'dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNAOTE3Mzg2OTcwI3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBBUFA=' //SCC
    const param = query.get('z'); // || 'dXNlck5hbWVAQWdpbGVDYXJkYXBpbyNsaWNAODg1NTQwNTQzI3ZlcnNpb25AMS4wI2VudkBQUkQjbW9kZUBBUFA=' //DEV

    let domainHash;

    if (param) {
      domainHash = param;
    } else {
      const domain = window.location.hostname;
      domainHash = hashMock.hashes.find((hash) => hash.domain === domain)?.hash;
      if (domain === 'villacaluipizzaria.com.br') {
        ReactPixel.init('718143809709082');
        ReactPixel.pageView();
        //ReactPixel.track('cart');
      }
    }

    if (!domainHash) {
      setNoHashError(true);
      return;
    }

    dispatch(setHash(domainHash));

    const transformedParam = decode(domainHash)?.split('#');

    const userName = transformedParam[0]?.split('@')[1];
    const license = transformedParam[1]?.split('@')[1];
    const version = transformedParam[2]?.split('@')[1];
    const environment = transformedParam[3]?.split('@')[1];
    const mode = transformedParam[4]?.split('@')[1];

    if (mode === 'SALAO') {
      dispatch(setType('salao'));
    }
    // if (license === '699220514') {
    //   InitiateFacebookTracking();
    // }
    if (!userName || !license || !version || !environment) {
      setNoHashError(true);
      return;
    }

    dispatch(define({ userName, license, version, environment }));
  }, [query]);

  useEffect(() => {
    console.log(`Type: ${type}`);
  }, [type]);

  useEffect(() => {
    if (!refreshed) return;

    dispatch(
      setAddress(
        localStorage.getItem(`selected_address_${userData.unique_name}`)
      )
    );
  }, [refreshed]);

  const isLoading =
    isTokenLoading ||
    isInfoLoading ||
    isUserLoading ||
    isCardapioLoading ||
    isItemLoading;

  useEffect(() => {
    const domain = window.location.hostname;

    if (domain === 'villacaluipizzaria.com.br') {
      ReactPixel.pageView();
      //ReactPixel.track('cart');
    }
  }, [location]);


  useEffect(()=> {
    const trackingInstance: TrackingValidator = new TrackingValidator(info);
    if (trackingInstance.ValidateTracking()) {
      InitiateFacebookTracking();
    }
  }, [info])

  return (
    <>
        <ThemeProvider theme={mock}>
          <GlobalStyle />
          {noHashError ? (
            <Error text='Você precisa de um link de acesso válido para entrar no cardápio.' />
          ) : (!info.Codigo || !params.Id || !tokenData.access_token) &&
            isError ? (
            <Error text='Algum erro ocorreu durante o carregamento da loja, tente novamente mais tarde.' />
          ) : (
            <>
              <Router />
            </>
          )}
        </ThemeProvider>
        {isLoading && <ScaleLoader />}
    </>
  );
}

export default App;


import { useState } from 'react'
import { PDHeaderItemBox } from '../styles'

type PDHeaderItemProps = {
  background: string,
  action: (image: string) => void
}

function PDHeaderItem({ background, action }: PDHeaderItemProps) {
  const [imageError, setImageError] = useState<boolean>(false)

  function handleItemClick () {
    if (imageError) return

    action(background)
  }

  return (
    <>
      <PDHeaderItemBox onClick={handleItemClick} className="item" background={background}>
        <div className="dark_film" />
        <img
          className="item_image"
          src={background}
          alt="Imagem do item" 
          onError={() => setImageError(true)}
        />
      </PDHeaderItemBox>
    </>
  )
}

export default PDHeaderItem

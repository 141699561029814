import styled from 'styled-components'

export const ComplementContainerGroup = styled.div`
  .complement_details {
    display: flex;
    align-items: center;

    .image {
      width: 8rem;

      margin-right: 1.2rem;

      img {
        width: 100%;
      }
    }

    .complement_name {
      font-size: 1.2rem;
      font-weight: 700;

      margin-bottom: .4rem;
    }

    .complement_pricing {
      color: #bbbbbb;

      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  .special_options {
    display: flex;

    padding: 1rem 0;

    overflow-x: auto;

    button {
      flex: none;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      border: 2px solid #aaaaaa;
      border-radius: .8rem;
      color: #aaaaaa;
      font-size: .9rem;
      font-weight: 700;

      width: 10rem;
      height: 10rem;

      margin: 0 .3rem;

      white-space: initial;

      img {
        filter: invert(76%) sepia(2%) saturate(0%) hue-rotate(136deg) brightness(89%) contrast(95%);

        margin: .6rem 0 .3rem;
      }

      &.selected {
        border-color: #ff0000;
        color: #ff0000;

        img {
          width: 2.4rem;

          filter: none;
        }
      }
    }
  }

  .multiple_checkbox {
    display: flex;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    span {
      width: 4rem;

      font-size: 1.4rem;
      font-weight: 700;

      text-align: center;
    }
  }
`

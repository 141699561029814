import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import LoginForm from './components/LoginForm'
import { SignContainer } from './styles'
import RegisterForm from './components/RegisterForm'
import AgilePageLayout from '../../components/AgilePageLayout'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import { cleanBackAddress } from '../../store/features/item/itemSlice'

function Sign () {
  const { accessToken } = useSelector((state: RootState) => state.user)
  const { backToItem } = useSelector((state: RootState) => state.item)

  const [register, setRegister] = useState<boolean>(false)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleGoBackButton () {
    if (register) {
      return setRegister(false)
    } else {
      if (backToItem) {
        navigate(`/loja/cardapio/${backToItem}${search}`)
        dispatch(cleanBackAddress())
      } else {
        navigate(-1)
      }
    }
  }

  useEffect(() => {
    if (!accessToken) return

    if (backToItem) {
      navigate(`/loja/cardapio/${backToItem}${search}`)
      dispatch(cleanBackAddress())
    } else {
      navigate(`/loja/cardapio${search}`)
    }
  }, [accessToken])

  return (
    <AgilePageLayout goBack={handleGoBackButton}>
      <SignContainer>
        {
          register
            ? <RegisterForm />
            : <LoginForm goToRegister={() => setRegister(true)} />
        }
      </SignContainer>
    </AgilePageLayout>
  )
}
export default Sign

import { ChangeEvent } from 'react'
import styled from 'styled-components'
import { OnlinePaymentOptionsType } from '..'
import { ErrorType } from '../../../hooks/useFormError'
import Field from '../../../components/Field'

type PaymentFormProps = {
  options: OnlinePaymentOptionsType,
  change: ((e: ChangeEvent<HTMLInputElement>) => void),
  clear: (field: string) => void,
  error: (field: string) => ErrorType | undefined
}

const PaymentFormContainer = styled.div`
  .flex-section {
    display: flex;

    @media (max-width: 350px) {
      flex-direction: column;
    }
  }
`

function PaymentForm ({ options, change, error, clear }: PaymentFormProps) {
  const fieldPropsTemplate = {
    type: 'string',
    change
  }

  const nameFieldProps = {
    ...fieldPropsTemplate,
    value: options.name,
    placeholder: 'Nome (igual impresso no cartão)',
    clear: () => clear('name'),
    name: 'name',
    error: error('name')
  }

  const cardNumberFieldProps = {
    ...fieldPropsTemplate,
    value: options.card.number,
    placeholder: 'Número do cartão',
    clear: () => clear('cardNumber'),
    name: 'cardNumber',
    error: error('cardNumber')
  }

  const cardExpiringDateFieldProps = {
    ...fieldPropsTemplate,
    value: options.card.expiringDate,
    placeholder: 'Validade',
    clear: () => clear('expiringDate'),
    name: 'expiringDate',
    error: error('expiringDate')
  }

  const cardCvvFieldProps = {
    ...fieldPropsTemplate,
    value: options.card.cvv,
    placeholder: 'CVV',
    clear: () => clear('cvv'),
    name: 'cvv',
    error: error('cvv')
  }

  const cpfFieldProps = {
    ...fieldPropsTemplate,
    value: options.cpf,
    placeholder: 'CPF (titular do cartão)',
    clear: () => clear('cpf'),
    name: 'cpf',
    error: error('paymentCpf')
  }

  return (
    <PaymentFormContainer>
      <Field fieldProps={nameFieldProps}/>
      <Field fieldProps={cardNumberFieldProps} />
      <div className="flex-section">
        <Field fieldProps={cardExpiringDateFieldProps} />
        <Field fieldProps={cardCvvFieldProps} />
      </div>
      <Field fieldProps={cpfFieldProps} />
    </PaymentFormContainer>
  )
}

export default PaymentForm

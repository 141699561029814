import { createSlice } from '@reduxjs/toolkit'

import { ItemOnCart } from '../../../types/cart'

type ClearType = {
  type: string,
  payload: 'withdraw' | 'delivery' | 'drive'
}

type AddItemType = {
  type: string,
  payload: {
    cart: 'withdraw' | 'delivery' | 'drive',
    item: ItemOnCart
  },
}

type RemoveItemType = {
  type: string,
  payload: {
    cart: 'withdraw' | 'delivery' | 'drive',
    id: string
  }
}

type ChangeItemQtdType = {
  type: string,
  payload: {
    cart: 'withdraw' | 'delivery' | 'drive',
    id: string,
    type: 'plus' | 'minus'
  }
}

type DefineDiscountType = {
  type: string,
  payload: {
    cart: 'withdraw' | 'delivery' | 'drive',
    Itens: ItemOnCart[]
  }
}

const initialState = {
  deliveryCart: [] as ItemOnCart[],
  withdrawCart: [] as ItemOnCart[],
  driveCart: [] as ItemOnCart[]
}

const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    clear: (state, action: ClearType) => {
      switch (action.payload) {
        case 'withdraw':
          state.withdrawCart = []
          break

        case 'delivery':
          state.deliveryCart = []
          break

        case 'drive':
          state.driveCart = []
          break

        default: break
      }
    },
    addItem: (state, action: AddItemType) => {
      switch (action.payload.cart) {
        case 'withdraw':
          state.withdrawCart = [
            ...state.withdrawCart,
            action.payload.item
          ]
          break

        case 'delivery':
          state.deliveryCart = [
            ...state.deliveryCart,
            action.payload.item
          ]
          break

        case 'drive':
          state.driveCart = [
            ...state.driveCart,
            action.payload.item
          ]
          break

        default: break
      }
    },
    removeItem: (state, action: RemoveItemType) => {
      switch (action.payload.cart) {
        case 'withdraw':
          state.withdrawCart = state.withdrawCart.filter((cartItem) => cartItem.id !== action.payload.id)
          break

        case 'delivery':
          state.deliveryCart = state.deliveryCart.filter((cartItem) => cartItem.id !== action.payload.id)
          break

        case 'drive':
          state.driveCart = state.driveCart.filter((cartItem) => cartItem.id !== action.payload.id)
          break

        default: break
      }
    },
    changeItemQtd: (state, action: ChangeItemQtdType) => {
      switch (action.payload.cart) {
        case 'withdraw':
          state.withdrawCart = state.withdrawCart.map((item) => (
              item.id === action.payload.id
                ? {
                    ...item,
                    Quantidade: action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1,
                    ValorTotal: item.ValorUnitario * (action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1)
                  }
                : item
            ))
          break

        case 'delivery':
          state.deliveryCart = state.deliveryCart.map((item) => (
            item.id === action.payload.id
              ? { 
                  ...item,
                  Quantidade: action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1,
                  ValorTotal: item.ValorUnitario * (action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1)
                }
              : item
          ))
          break

        case 'drive':
          state.driveCart = state.driveCart.map((item) => (
            item.id === action.payload.id
              ? { 
                ...item,
                Quantidade: action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1,
                ValorTotal: item.ValorUnitario * (action.payload.type === 'plus' ? item.Quantidade + 1 : item.Quantidade - 1)
              }
              : item
          ))

          break
        default: break
      }
    },
    defineDiscount: (state, action: DefineDiscountType) => {
      switch (action.payload.cart) {
        case 'withdraw':
          state.withdrawCart = state.withdrawCart.map((item, index) => ({
            ...item,
            CodigoCampanha: action.payload.Itens[index].CodigoCampanha,
            CodigoCampanhaFamilia: action.payload.Itens[index].CodigoCampanhaFamilia,
            ValorDesconto: action.payload.Itens[index].ValorDesconto
          }))
          break

        case 'delivery':
          state.deliveryCart = state.deliveryCart.map((item, index) => ({
            ...item,
            CodigoCampanha: action.payload.Itens[index].CodigoCampanha,
            CodigoCampanhaFamilia: action.payload.Itens[index].CodigoCampanhaFamilia,
            ValorDesconto: action.payload.Itens[index].ValorDesconto
          }))
          break

        case 'drive':
          state.driveCart = state.driveCart.map((item, index) => ({
            ...item,
            CodigoCampanha: action.payload.Itens[index].CodigoCampanha,
            CodigoCampanhaFamilia: action.payload.Itens[index].CodigoCampanhaFamilia,
            ValorDesconto: action.payload.Itens[index].ValorDesconto
          }))
          break

        default: break
      }
    }
  }
})

export const { clear, addItem, removeItem, changeItemQtd, defineDiscount } = cartSlice.actions
export default cartSlice.reducer

import AgilePageLayout from '../../components/AgilePageLayout'
import styled from 'styled-components'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import useFormError from '../../hooks/useFormError'
import { validateCodeField, validateConfirmPasswordField, validateEmailField, validatePasswordField } from '../../utils/fieldValidations'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../store/store'
import axios from 'axios'
import OverlayNotification from '../../components/OverlayNotification'
import ScaleLoader from '../../components/ScaleLoader'

import { disconnect, loginWithCredentials, reset } from '../../store/features/user/userSlice'
import ConfirmationCode from './components/ConfirmationCode'
import SendEmail from './components/SendEmail'
import ChangePassword from './components/ChangePassword'

const RestorePasswordContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .label {
    color: #464646;

    font-size: 1.2rem;
    font-weight: 700;

    text-align: center;

    margin: 1rem 0 2rem;
  }

  .main_button {
    background: #ff0000;
    border-radius: 2.4rem;
    color: #ffffff;

    font-weight: 700;

    margin-top: .6rem;
    padding: 1rem;

    width: 100%;
  }

  .image_wrapper {
    max-width: 15rem;
    width: 40%;

    img {
      width: 100%;
    }

    p {
      font-size: 1.2rem;
    }
  }

  .fields {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-bottom: 2rem;

    .input_field_wrapper {
      border: 1px solid #000000;
      border-radius: .6rem;

      width: 6rem;
      height: 6rem;

      display: flex;
      justify-content: center;
      align-items: center;

      input {
        background: none;
        border: 0;

        width: 1.4ch;

        font-size: 1.8rem;
      }
    }

    .error {
      border: 1px solid #ff0000;
    }
  }

  .actions {
    display: flex;
    justify-content: space-between;

    width: 100%;

    margin-top: 2rem;

    p {
      color: #666666;

      font-size: 1.2rem;
      font-weight: 700;

      button {
        margin-left: 1rem;
      }
    }

    button {
      background: none;
      
      font-size: 1.2rem;
      font-weight: 700;
    }

    > button {
      color: #ff0000;
    }
  }

  .error {
    font-size: 1.2rem;
    font-weight: 700;

    color: #ff0000;

    margin-bottom: .8rem;
  }
`

function RestorePassword() {
  const { tokenData, storeParams } = useSelector((state: RootState) => state.token)
  const { userData, isSuccess, isError, accessToken } = useSelector((state: RootState) => state.user)

  const [email, setEmail] = useState<string>('')
  const [error, setError] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [definedEmail, setDefinedEmail] = useState<boolean>(false)
  const [code, setCode] = useState<string[]>(['', '', '', ''])

  const [successful, setSuccessful] = useState<boolean>(false)

  const firstCodeInputReference = useRef<HTMLInputElement>(null)
  const secondCodeInputReference = useRef<HTMLInputElement>(null)
  const thirdCodeInputReference = useRef<HTMLInputElement>(null)
  const fourthCodeInputReference = useRef<HTMLInputElement>(null)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [
    setNewError,
    getErrorByFieldname,
    cleanErrorsByFieldname
  ] = useFormError()

  useEffect(() => {
    if (!firstCodeInputReference.current) return

    firstCodeInputReference.current.focus()
  }, [firstCodeInputReference.current])

  function handleEmailChange (e: ChangeEvent<HTMLInputElement>) {
    setEmail(e.target.value)
  }

  function handleCodeFieldChange (e: ChangeEvent<HTMLInputElement>) {
    switch (e.target.id) {
      case 'f1':
        setCode((prevState) => ([
          e.target.value,
          prevState[1],
          prevState[2],
          prevState[3]
        ]))

        if (!secondCodeInputReference.current?.value) {
          secondCodeInputReference.current?.focus()
        } else if (!thirdCodeInputReference.current?.value) {
          thirdCodeInputReference.current?.focus()
        } else if (!fourthCodeInputReference.current?.value) {
          fourthCodeInputReference.current?.focus()
        }

        break

      case 'f2':
        setCode((prevState) => ([
          prevState[0],
          e.target.value,
          prevState[2],
          prevState[3]
        ]))

        if (!thirdCodeInputReference.current?.value) {
          thirdCodeInputReference.current?.focus()
        } else if (!fourthCodeInputReference.current?.value) {
          fourthCodeInputReference.current?.focus()
        }

        break

      case 'f3':
        setCode((prevState) => ([
          prevState[0],
          prevState[1],
          e.target.value,
          prevState[3]
        ]))

        if (!fourthCodeInputReference.current?.value) {
          fourthCodeInputReference.current?.focus()
        }
        break

      case 'f4':
        setCode((prevState) => ([
          prevState[0],
          prevState[1],
          prevState[2],
          e.target.value
        ]))
        break

      default: break
    }
  }

  function validateFields (): boolean {
    const isEmailValidated = validateEmailField(
      email,
      setNewError,
      cleanErrorsByFieldname
    )

    return isEmailValidated
  }

  async function handleSendEmail (jumpVerification?: boolean) {
    if (validateFields() || jumpVerification) {
      const env = process.env.REACT_APP_RESTORE_EMAIL_URI

      if (!env || !storeParams.api || !tokenData.access_token) return

      try {
        setIsLoading(true)

        const restoreEmailData = {
          UserName: email
        }

        await axios({
          method: 'POST',
          url: `${storeParams.api}/${env}`,
          data: restoreEmailData,
          headers: {
            Authorization: `Bearer ${tokenData.access_token}`
          }
        })

        setDefinedEmail(true)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
        setError('Algum erro aconteceu durante a restauração, verifique o email ou tente novamente mais tarde.')
      }
    }
  }

  function clearCode () {
    setCode(['', '', '', ''])
  }

  async function validateCode () {
    const isCodeFieldValid = validateCodeField(
      code.join(''),
      setNewError,
      cleanErrorsByFieldname
    )

    if (isCodeFieldValid) {
      const env = process.env.REACT_APP_CREDENTIAL_LOGIN_URI

      if (!env || !storeParams.api || !tokenData.access_token) return

      setIsLoading(true)

      const loginWithCredentialsProps = {
        credentials: {
          UserName: email,
          Password: code.join('')
        },
        token: tokenData.access_token,
        domain: storeParams.api
      }

      dispatch(loginWithCredentials(loginWithCredentialsProps))
    }
  }

  function validatePasswords (password: string, confirmPassword: string) {
    const isPasswordFieldValid = validatePasswordField(
      password,
      setNewError,
      cleanErrorsByFieldname
    )

    const isConfirmPasswordFieldValid = validateConfirmPasswordField(
      confirmPassword,
      password,
      setNewError,
      cleanErrorsByFieldname
    )

    return (
      isPasswordFieldValid &&
      isConfirmPasswordFieldValid
    )
  }

  async function handleUpdatePassword (password: string, confirmPassword: string) {
    if (!validatePasswords(password, confirmPassword)) return

    const env = process.env.REACT_APP_CHANGE_PASSWORD_URI

    if (!env || !storeParams.api || !accessToken) return

    setIsLoading(true)

    try {
      const changePasswordData = {
        Login: email,
        Nova: password,
        ConfirmaNova: confirmPassword
      }

      await axios({
        method: 'PUT',
        url: `${storeParams.api}/${env}`,
        data: changePasswordData,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      setIsLoading(false)
      setSuccessful(true)
    } catch (err) {
      setIsLoading(false)
      setError('Algum erro ocorreu durante a alteração da senha, tente novamente mais tarde.')
    }
  }

  function finishOperation () {
    navigate(`/loja/cardapio${search}`)
  }

  function handleGoBack () {
    dispatch(disconnect())
    navigate(`/login${search}`)
  }

  useEffect(() => {
    if (!isSuccess && !isError) return

    if (isError) {
      setIsLoading(false)
      setError('Ocorreu algum erro de autenticação, revise o código ou tente novamente mais tarde.')
    }

    if (isSuccess) {
      setIsLoading(false)
    }

    dispatch(reset())
  }, [isSuccess, isError])

  const emailFieldProps = {
    value: email,
    placeholder: 'Digite seu e-mail...',
    change: handleEmailChange,
    clear: () => setEmail(''),
    error: getErrorByFieldname('email'),
    name: 'email'
  }

  const codeError = getErrorByFieldname('code')

  const sendEmailProps = {
    emailFieldProps,
    handleSendEmail
  }

  const confirmationCodeProps = {
    firstRef: firstCodeInputReference,
    secondRef: secondCodeInputReference,
    thirdRef: thirdCodeInputReference,
    fourthRef: fourthCodeInputReference,
    codeError,
    codeValue: code,
    clearCode,
    validateCode,
    handleSendEmail,
    handleCodeFieldChange
  }

  const changePasswordProps = {
    handleUpdatePassword,
    passwordError: getErrorByFieldname('password'),
    confirmPasswordError: getErrorByFieldname('confirmPassword')
  }

  return (
    <AgilePageLayout goBack={() => navigate(-1)}>
      <RestorePasswordContainer>
        {
          (!definedEmail && !userData.email) && <SendEmail sendEmailProps={sendEmailProps} />
        }
        {
          (definedEmail && !userData.email) && <ConfirmationCode confirmationCodeProps={confirmationCodeProps} />
        }
        {
          (definedEmail && userData.email) && <ChangePassword changePasswordProps={changePasswordProps} />
        }
      </RestorePasswordContainer>
      {
        error && <OverlayNotification text={error} close={handleGoBack} />
      }
      {
        isLoading && <ScaleLoader />
      }
      {
        successful && (
          <OverlayNotification text="Senha modificada com sucesso!" close={finishOperation} />
        )
      }
    </AgilePageLayout>
  )
}
export default RestorePassword

import { ChevronLeft } from 'lucide-react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { clear } from '../../../store/features/cart/cartSlice'
import { CartHeaderContainer } from '../styles'

type CartHeaderProps = {
  currentCart: 'withdraw' | 'delivery' | 'drive'
}

function CartHeader ({ currentCart }: CartHeaderProps) {
  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  function handleGoBack () {
    navigate(`/loja/cardapio${search}`)
  }

  function handleClear () {
    dispatch(clear(currentCart))

    handleGoBack()
  }

  return (
    <CartHeaderContainer className="page_context">
      <div>
        <button type="button" onClick={handleGoBack}>
          <ChevronLeft size={42} color="#ff0000" />
        </button>
        <h2>CARRINHO</h2>
      </div>
      <button type="button" onClick={handleClear}>Limpar</button>
    </CartHeaderContainer>
  )
}
export default CartHeader

import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { Clock, MapPin } from 'lucide-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getStoreInfo } from '../../store/features/info/infoSlice'
import { RootState } from '../../store/store'
import { InfoContainer } from './styles'

function Info () {
  const { tokenData, storeParams } = useSelector((state: RootState) => state.token)
  const { info, isLoading } = useSelector((state: RootState) => state.info)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || ''
  })

  const dispatch = useDispatch()

  useEffect(() => {
    if (!tokenData.access_token) return

    if (!info && !isLoading && storeParams.api) {
      const getStoreInfoProps = {
        token: tokenData.access_token,
        domain: storeParams.api
      }

      dispatch(getStoreInfo(getStoreInfoProps))
    }
  }, [storeParams.api])

  const storePosition = { lat: info.Endereco?.Location?.Latitude, lng: info.Endereco?.Location?.Longitude }

  console.log(info)
console.log(storePosition)

  return (
    <InfoContainer className="page_context">
      <div className="address">
        <h3>Endereço</h3>
        <div className="address_info">
          <div className="icon">
            <MapPin size={24} color="#ffff00" />
          </div>
          <div>
            <p>{info.Endereco?.Log}</p>
            <p>{info.Endereco?.Bai.d}</p>
            <p>{info.Endereco?.Cid.d}/{info.Endereco?.Uf}</p>
          </div>
        </div>
        <div className="map">
          { isLoaded && storePosition.lat && storePosition.lng && (
            <GoogleMap
              mapContainerStyle={{ width: '100%', height: '100%' }}
              center={storePosition}
              zoom={15}
            >
              <Marker position={storePosition} />
            </GoogleMap>
          ) }
        </div>
      </div>
      <div className="business_hours">
        <h3>Horário de Funcionamento</h3>
        <div>
          <div className="icon">
            <Clock size={20} color="#ffff00" />
          </div>
          <div>
            <p>Das {info.HorarioAtendimentoInicial} ás {info.HorarioAtendimentoFinal}</p>
            <p>Abre às {info.HorarioAtendimentoInicial} - Antecipe seu pedido</p>
          </div>
        </div>
      </div>
    </InfoContainer>
  )
}

export default Info

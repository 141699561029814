import { MapPin } from 'lucide-react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { StoreCardContainer } from '../styles'

function StoreCard () {
  const { info, params } = useSelector((state: RootState) => state.info)

  return (
    <StoreCardContainer background={params.FotoUri} className="small_context">
      <div className="image"></div>
      <div className="info">
        <p>{info.Nome?.toUpperCase()}</p>
        {
          info.Endereco && (
            <span>
              <MapPin size={20} />
              {info.Endereco.Log}
            </span>
          )
        }
        <div className="distance">
          2 km
        </div>
      </div>
    </StoreCardContainer>
  )
}

export default StoreCard

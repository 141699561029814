import { createSlice, PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  notification: ''
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotification: (state, action: PayloadAction<string>) => {
      state.notification = action.payload
    },
    cleanNotification: (state) => {
      state.notification = ''
    }
  }
})

export const { setNotification, cleanNotification } = notificationSlice.actions
export default notificationSlice.reducer

import { X } from 'lucide-react'
import { ChangeEvent, ReactNode } from 'react'
import styled from 'styled-components'
import { ErrorType } from '../hooks/useFormError'

export type SpecificFieldProps = {
  value: string | number,
  placeholder: string,
  change: (e: ChangeEvent<HTMLInputElement>) => void,
  clear: () => void,
  type?: string,
  name?: string,
  error?: ErrorType | undefined,
}

type FieldProps = {
  fieldProps: SpecificFieldProps,
  children?: ReactNode
}

const FieldContainer = styled.div`
  width: 100%;
  flex: 1;

  > div {
    width: 100%;

    background: #ffffff;
    border: 2px solid #ffffff;
    border-radius: 1rem;

    display: flex;
    align-items: center;

    padding: 1rem .6rem;
    margin: .4rem 0 1rem;

    box-shadow: 0px 0px 9px 0px rgba(0,0,0,0.06);

    &.error {
      border: 2px solid #ff0000;

      margin-bottom: .6rem;
    }

    button {
      background: none;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    label {
      flex: 1;

      display: flex;
      align-items: center;

      svg {
        margin-right: 1.2rem;
      }
    }

    input {
      flex: 1;
      width: 100%;

      border: 0;

      &:focus {
        outline: 0;
      }
    }
  }

  .field_error {
    color: #ff0000;
    font-size: 1.2rem;
    font-weight: 700;

    display: block;

    margin-left: .6rem;
    margin-bottom: 1.2rem;
  }
`

function Field ({ fieldProps, children }: FieldProps) {
  const { value, change, clear, placeholder, type, name, error } = fieldProps

  return (
    <FieldContainer>
      <div className={error?.message ? 'error' : undefined}>
        <label>
          { children }
          <input
            type={type || 'text'}
            placeholder={placeholder}
            value={value}
            onChange={change}
            name={name}
          />
        </label>
        <button type="button" onClick={clear}>
          <X size={24} color="#999999" />
        </button>
      </div>
      { error?.message && <span className="field_error">{error.message}</span> }
    </FieldContainer>
  )
}
export default Field

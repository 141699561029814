import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FooterMenu from './components/FooterMenu'
import { getCardapio, setType } from '../../store/features/cardapio/cardapioSlice'
import { RootState } from '../../store/store'
import { CardapioContainer } from './styles'
import Estimate from '../../components/Estimate'
import AddressButton from './components/AddressButton'
import AddressSelector from './components/AddressSelector'
import Categories from './components/Categories'
import axios from 'axios'
import Warning from './components/Warning'
import { useLocation, useNavigate } from 'react-router-dom'
import { updateFee } from '../../store/features/info/infoSlice'
import { SaleModes } from '../../components/SaleModes'

function Cardapio() {
  const { cardapio, categories, isLoading, type } = useSelector((state: RootState) => state.cardapio)
  const { tokenData, storeParams } = useSelector((state: RootState) => state.token)
  const { selectedAddress } = useSelector((state: RootState) => state.user)
  const { info } = useSelector((state: RootState) => state.info)

  const [verifying, setVerifying] = useState<boolean>(false)

  const [isAddressSelectorOpen, setIsAddressSelectorOpen] = useState<boolean>(false)
  const [attendNeighbourhood, setAttendNeighbourhood] = useState<boolean>(false)

  const { search } = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function handleAddressSelector() {
    setIsAddressSelectorOpen(true)
  }

  

  useEffect(() => {

    //if (!tokenData.access_token || !storeParams.api || cardapio || isLoading) return
    if (!tokenData.access_token || !storeParams.api || isLoading) return

    
    const getCardapioParams = {
      option: type,
      token: tokenData.access_token,
      domain: storeParams.api
    }

    if (type === 'entrega' && !selectedAddress) {
      return
    }

    dispatch(getCardapio(getCardapioParams))
  }, [type, tokenData.access_token, selectedAddress, storeParams.api])

  useEffect(() => {
    const env = process.env.REACT_APP_STORE_ATTENDS_URI

    if (type !== 'entrega' || !storeParams.api || !env) return

    (async () => {
      setVerifying(true)

      try {
        const response = await axios({
          method: 'GET',
          url: `${storeParams.api}/${process.env.REACT_APP_STORE_ATTENDS_URI}/${selectedAddress.Bai.c}`,
          headers: {
            Authorization: `Bearer ${tokenData.access_token}`
          }
        })

        if (response.data.Codigo !== info.Codigo) {
          throw new Error('Incompatible store code')
        }

        setVerifying(false)
        setAttendNeighbourhood(true)
        dispatch(updateFee({ fee: response.data.TaxaEntrega }))
      } catch (err) {
        setVerifying(false)
        setAttendNeighbourhood(false)
      }
    })()
  }, [type, selectedAddress])

  useEffect(() => {
    if (info.LojaEntrega === 'NAO' && type === 'entrega') {
      if (info.LojaRetirada === 'SIM') {
        dispatch(setType('retirada'))
      } else if (info.LojaDrive === 'SIM') {
        dispatch(setType('drive'))
      }
    }

    if (info.LojaRetirada === 'NAO' && type === 'retirada') {
      if (info.LojaEntrega === 'SIM') {
        dispatch(setType('entrega'))
      } else if (info.LojaDrive === 'SIM') {
        dispatch(setType('drive'))
      }
    }

    if (info.LojaDrive === 'NAO' && type === 'drive') {
      if (info.LojaEntrega === 'SIM') {
        dispatch(setType('entrega'))
      } else if (info.LojaRetirada === 'SIM') {
        dispatch(setType('retirada'))
      }
    }
  }, [info.LojaDrive, info.LojaRetirada, info.LojaEntrega])

  return (
    <CardapioContainer className="page_context">
      <section className="options">
        {
          type !== 'salao' && type !== '' && (
            <div className="catalog_types">
              {
                info.LojaRetirada === 'SIM' && (
                  <button
                    className={type === 'retirada' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('retirada'))}
                  >
                    Retirada
                  </button>
                )
              }
              {
                info.LojaEntrega === 'SIM' && (
                  <button
                    className={type === 'entrega' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('entrega'))}
                  >
                    Entrega
                  </button>
                )
              }
              {
                info.LojaDrive === 'SIM' && (
                  <button
                    className={type === 'drive' ? 'active' : 'not-active'}
                    type="button"
                    onClick={() => dispatch(setType('drive'))}
                  >
                    Drive
                  </button>
                )
              }
            </div>
          )
        }
      </section>
      {
        (type === 'entrega' && !selectedAddress?.Log)
          ? <Warning
            text="Você precisa de um endereço para acessar o cardápio de entrega"
            btnText="Adicionar endereço"
            action={() => navigate(`/endereco${search}`)}
          />
          : !isLoading && !verifying && (type === 'entrega' && !attendNeighbourhood)
              ? (
                <>
                  <AddressButton
                    selectedAddress={selectedAddress}
                    handleAddressSelector={handleAddressSelector}
                  />
                  <Warning
                    text="Essa loja não atende esse endereço"
                    btnText="Adicionar novo endereço"
                    action={() => navigate(`/endereco${search}`)}
                  />
                </>
              )
              : (
                <>
                  {type === 'entrega' && selectedAddress?.Log && cardapio.length > 0 && (
                    <AddressButton
                      selectedAddress={selectedAddress}
                      handleAddressSelector={handleAddressSelector}
                    />
                  )}
                  {
                    type !== 'salao' && cardapio.length > 0 && type !== '' && (
                      <Estimate />
                    )
                  }
                  <p id="app_mode">Mode: {type === 'salao' ? 'LOJA' : 'APP'}</p>
                  {type === '' && <SaleModes />}
                  {cardapio && type !== '' && <Categories cardapio={cardapio} categories={categories} />}
                </>
              )
      }
      <FooterMenu type={type} />
      {
        isAddressSelectorOpen && (
          <AddressSelector
            close={() => setIsAddressSelectorOpen(false)}
          />
        )
      }
    </CardapioContainer>
  )
}

export default Cardapio

import styled from 'styled-components'

export const SignContainer = styled.div`
  label {
    background: #ffffff;
    border: 2px solid #aaaaaa;
    border-radius: .4rem;

    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: .4rem .8rem;
    margin-bottom: 1rem;

    input {
      border: 0;
      
      width: 100%;

      &:focus {
        outline: 0;
      }
    }

    button {
      background: none;
    }

    &.error {
      border: 2px solid #ff0000;
      color: #ff0000;

      & + span {
        color: #ff0000;
        font-size: 1.2rem;
        font-weight: 700;

        display: block;

        margin-bottom: 1rem;
      }
    }
  }
`

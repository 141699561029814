import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import Error from '../../components/Error'
import { reset } from '../../store/features/cardapio/cardapioSlice'
import { reset as userReset } from '../../store/features/user/userSlice'
import { RootState } from '../../store/store'
import { HomeContainer } from './styles'
import { InitiateFacebookTracking } from '../../monitoring/facebook'

function Home () {
  const { info, isError: storeError } = useSelector((state: RootState) => state.info)
  const { storeParams, isError: tokenError } = useSelector((state: RootState) => state.token)
  const { isError: menuError } = useSelector((state: RootState) => state.cardapio)
  const { isError: userError } = useSelector((state: RootState) => state.user)

  const { search } = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  function handleButtonClick () {
    navigate(`/loja/cardapio${search}`)
  }

  // useEffect(()=> {
  //   const needTracking = info.Storage === 'BLC';
    
  //   if (needTracking) {
  //     InitiateFacebookTracking();
  //   }
  // }, [info])

  useEffect(() => {
    dispatch(reset())
  }, [])

  useEffect(() => {
    if (!userError) return

    localStorage.removeItem('refresh_token')
  }, [userError])

  function handleUserErrorGoBack () {
    dispatch(userReset())
  }

  //const anyStoreError = (menuError || storeError || tokenError)
  const anyStoreError = (storeError || tokenError)

  return (
    <>
      {
        (!anyStoreError && !userError) && (
          <HomeContainer
            background={storeParams.store && info.Sigla ? `${storeParams.background}/${info.Sigla}1.jpg` : ''}
            color={storeParams.bgColor}
          >
            <div className="element_context_wrapper">
              <button type="button" onClick={handleButtonClick}>INICIAR</button>
              <span>v. {process.env.REACT_APP_VERSION}</span>
            </div>
          </HomeContainer>
        )
      }
      {
        userError
          ? (
              <Error
                text="Algum erro ocorreu na autenticação do usuário, tente novamente mais tarde."
                goBack={handleUserErrorGoBack}
              />
            )
          : anyStoreError && (
            <Error text="Essa loja não está disponível no momento nessa modalidade." />
          )
      }
    </>
  )
}

export default Home

import axios from 'axios'

async function getCardapio (option: string, token: string, domain: string) {
  const env = process.env.REACT_APP_CARDAPIO_URI

  if (!domain || !option || !env || !token) return

  const response = await axios({
    method: 'GET',
    url: `${domain}/${env}/${option}`,
    headers: {
      Authorization: `Bearer ${token}`
    }
  })

  return response
}

export const cardapioService = {
  getCardapio
}

import styled, { keyframes } from 'styled-components'

type NotificationContainerProps = {
  background: string
}

const goingIn = keyframes`
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0)
  }
`

const goingOut = keyframes`
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(100%)
  }
`

export const NotificationContainer = styled.div<NotificationContainerProps>`
  background: ${({ background }) => background};

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  text-align: center;

  padding: 1.5rem 1rem;

  animation-name: ${goingIn};
  animation-duration: 500ms;
  animation-iteration-count: 1;

  button {
    background: #f3f3f3;
    border-radius: .6rem;
    color: #ff0000;

    font-weight: 700;

    padding: .8rem;
  }

  p {
    color: #ffffff;

    font-size: 1.4rem;
    font-weight: 700;
  }

  &.going_out {
    animation-name: ${goingOut};
  }
`

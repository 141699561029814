import { ChangeEvent, useState } from 'react'
import { useSelector } from 'react-redux'
import Field from '../../../components/Field'
import { ErrorType } from '../../../hooks/useFormError'
import { RootState } from '../../../store/store'

type ChangePasswordProps = {
  changePasswordProps: {
    handleUpdatePassword: (newPassword: string, confirmPassword: string) => void,
    passwordError: ErrorType | undefined,
    confirmPasswordError: ErrorType | undefined
  }
}

function ChangePassword({ changePasswordProps }: ChangePasswordProps) {
  const { handleUpdatePassword, passwordError, confirmPasswordError } = changePasswordProps

  const { userData } = useSelector((state: RootState) => state.user)

  const [password, setPassword] = useState<string>('')
  const [confirmPassword, setConfirmPassword] = useState<string>('')

  const passwordFieldProps = {
    value: password,
    placeholder: 'Digite sua senha...',
    type: 'password',
    change: (e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value),
    clear: () => setPassword(''),
    error: passwordError
  }

  const confirmPasswordFieldProps = {
    value: confirmPassword,
    placeholder: 'Confirme sua senha...',
    type: 'password',
    change: (e: ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value),
    clear: () => setConfirmPassword(''),
    error: confirmPasswordError
  }

  function handleChangePassword() {
    handleUpdatePassword(password, confirmPassword)
  }

  return (
    <>
      <div className="image_wrapper">
        <p>Olá, <strong>{userData.CompleteUser.Usuario.Nome}!</strong></p>
      </div>
      <p className="label">Altere sua senha informando a nova nos campos abaixo.</p>
      <Field fieldProps={passwordFieldProps} />
      <Field fieldProps={confirmPasswordFieldProps} />
      <button
        type="button"
        className="main_button"
        onClick={handleChangePassword}
      >
        Alterar
      </button>
    </>
  )
}
export default ChangePassword

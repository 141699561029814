import { createAsyncThunk, createSlice, current, PayloadAction } from '@reduxjs/toolkit'
import jwtDecode from 'jwt-decode'
import { AddressType } from '../../../types/address'
import {
  LoginCredentials,
  LoginRequestType,
  RegisterCredentials,
  UnparsedUserDataType,
  UserDataType
} from '../../../types/login'
import { userService } from './userService'

type RefreshLoginProps = {
  token: string,
  domain: string
}

type LoginWithCredentialsProps = {
  credentials: LoginCredentials,
  token: string,
  domain: string
}

type AddNewAddressProps = {
  address: AddressType,
  userToken: string,
  userId: number,
  domain: string
}

type RegisterProps = {
  credentials: RegisterCredentials,
  token: string,
  domain: string
}

const initialState = {
  userData: {} as UserDataType,
  userId: 0,
  refreshed: false,
  selectedAddress: {} as AddressType,
  accessToken: '',
  refreshToken: '',
  hash: '',
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
}

export const loginWithRefreshToken = createAsyncThunk(
  'user/refresh_login',
  async (props: RefreshLoginProps, thunkApi) => {
    try {
      const response = await userService.loginWithRefreshToken(props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message = err.response?.data?.Messages?.[0]

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const loginWithCredentials = createAsyncThunk(
  'user/credential_login',
  async (props: LoginWithCredentialsProps, thunkApi) => {
    try {
      const response = await userService.loginWithCredentials(props.credentials, props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message = 
        err.response?.data?.Messages?.[0]

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const register = createAsyncThunk(
  'user/register',
  async (props: RegisterProps, thunkApi) => {
    try {
      const response = await userService.register(props.credentials, props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message = err.response?.data?.Messages?.[0]

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const addNewAddress = createAsyncThunk(
  'user/add_new_address',
  async (props: AddNewAddressProps, thunkApi) => {
    try {
      const response = await userService.addNewAddress(props.address, props.userToken, props.userId, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
      (err.response &&
        err.response.data &&
        err.response.data.Messages[0]) ||
      err.message ||
      String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.refreshed = false
      state.message = ''
    },
    disconnect: (state) => {
      state.accessToken = ''
      state.refreshToken = ''
      state.userData = {} as UserDataType
      state.selectedAddress = {} as AddressType
      state.userId = 0
    },
    setAddress: (state, action: PayloadAction<string | null>) => {
      if (!state.accessToken) return

      const address = current(state.userData).CompleteUser.Usuario.Enderecos.find((address) => (
        `${address.Log.toUpperCase()}, ${address.Numero}`.trim().toUpperCase() === action.payload?.trim().toUpperCase()
      ))

      if (address) {
        state.selectedAddress = address
      } else {
        state.selectedAddress = state.userData.CompleteUser.Usuario.Enderecos[0]
      }
    },
    setOfflineAddress: (state, action: PayloadAction<AddressType>) => {
      state.selectedAddress = action.payload
    },
    setRefreshToken: (state, action: PayloadAction<string>) => {
      state.refreshToken = action.payload
    },
    setHash: (state, action: PayloadAction<string>) => {
      state.hash = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(loginWithRefreshToken.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginWithRefreshToken.fulfilled, (state, action: PayloadAction<LoginRequestType>) => {
        state.isLoading = false
        state.refreshToken = action.payload.refresh_token
        state.accessToken = action.payload.access_token
        state.userId = action.payload.Id
        state.refreshed = true

        const decodedAccessToken: UnparsedUserDataType = jwtDecode<UnparsedUserDataType>(action.payload.access_token)
        const parsedUserData: UserDataType = {
          ...decodedAccessToken,
          CompleteUser: JSON.parse(decodedAccessToken.CompleteUser)
        }

        state.userData = parsedUserData
      })
      .addCase(loginWithRefreshToken.rejected, (state, action: any) => {
        localStorage.removeItem('refresh_token')

        state.isLoading = false
        state.message = action.payload
      })
      .addCase(loginWithCredentials.pending, (state) => {
        state.isLoading = true
      })
      .addCase(loginWithCredentials.fulfilled, (state, action: PayloadAction<LoginRequestType>) => {
        state.isLoading = false
        state.isSuccess = true
        state.refreshToken = action.payload.refresh_token
        state.accessToken = action.payload.access_token
        state.userId = action.payload.Id

        const decodedAccessToken: UnparsedUserDataType = jwtDecode<UnparsedUserDataType>(action.payload.access_token)
        const parsedUserData: UserDataType = {
          ...decodedAccessToken,
          CompleteUser: JSON.parse(decodedAccessToken.CompleteUser)
        }

        state.userData = parsedUserData

        if (!state.hash) return
        localStorage.setItem(`refresh_token_${state.hash}`, state.refreshToken)
      })
      .addCase(loginWithCredentials.rejected, (state, action: any) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload || 'Tentativa de login inválida.'
      })
      .addCase(register.pending, (state) => {
        state.isLoading = true
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(register.rejected, (state, action: any) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(addNewAddress.pending, (state) => {
        state.isLoading = true
      })
      .addCase(addNewAddress.fulfilled, (state) => {
        state.isLoading = false
        state.isSuccess = true
      })
      .addCase(addNewAddress.rejected, (state) => {
        state.isLoading = false
        state.isError = true
      })
  }
})

export const { reset, setAddress, disconnect, setOfflineAddress, setRefreshToken, setHash } = userSlice.actions
export default userSlice.reducer

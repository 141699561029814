import { Additional as AdditionalType } from '../../../../types/cardapioItem'

type AdditionalProps = {
  add: AdditionalType,
  set: (add: AdditionalType) => void,
  checked: boolean,
  halfItem: boolean,
  quant: number
}

function Additional ({ add, set, checked, halfItem, quant }: AdditionalProps) {
  return (
    <label className="additional_box" key={add.Descricao + add.PrecoCheio}>
      <div className="custom_checkbox">
        <input
          type="checkbox"
          onChange={() => set(
            {
              Descricao: add.Descricao,
              PrecoCheio: add.PrecoCheio,
              PrecoMeioMeio: add.PrecoMeioMeio,
              Checked: add.Checked
            }
          )}
          checked={checked}
        />
        <span className="checkmark" />
      </div>
      <div className="additional_details">
        <p className="add_name">{add.Descricao}</p>
        <p className="add_price">+ R$ {((halfItem ? add.PrecoMeioMeio : add.PrecoCheio) * quant).toFixed(2)}</p>
      </div>
    </label>
  )
}
export default Additional

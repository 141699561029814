import { useDispatch, useSelector } from 'react-redux';
import { SalesModesContainer } from './styles';
import { useEffect, useState } from 'react';
import { requestIcons } from '../../assets/icons';
import { setType } from '../../store/features/cardapio/cardapioSlice';
import { RootState } from '../../store/store';

enum Answer {
  SIM = 'SIM',
  NAO = 'NAO',
}

type Mode = {
  meio: string;
  icon: string;
};

const fullModeList = [
  { meio: 'retirada', icon: requestIcons.takeAway },
  { meio: 'entrega', icon: requestIcons.delivery },
  { meio: 'drive', icon: requestIcons.driveThru }
];

export const SaleModes = () => {
  const heading = 'Como quer receber o pedido?';

  const dispatch = useDispatch();

  const { info } = useSelector((state: RootState) => state.info);

  const [salesMode, setSalesMode] = useState<Mode[]>([]);

  const handleDefaultModes = () => {
    if (!info || salesMode.length > 0) return;

    const possibleModes: string[] = [
      info.LojaRetirada,
      info.LojaEntrega,
      info.LojaDrive
    ];

    for (let i = 0; i < possibleModes.length; i++) {
      if (possibleModes[i] === Answer.SIM) {
        setSalesMode((prevSalesMode) => [...prevSalesMode, fullModeList[i]]);
      }
    }
  };

  useEffect(() => {
    handleDefaultModes();

    if (salesMode.length === 1) {
      dispatch(setType(salesMode[0].meio));
    }
  }, [info, salesMode]);

  return (
    <SalesModesContainer>
      <div className='heading'>
        <p>{heading}</p>
      </div>
      <div className='card-container'>
        {salesMode.map((mode: Mode) => (
            <div
              key={mode.meio}
              id={mode.meio}
              className='card'
              onClick={() => dispatch(setType(mode.meio))}
            >
              <img src={mode.icon} alt={mode.meio} />
              <p>{mode.meio}</p>
            </div>
        ))}
      </div>
    </SalesModesContainer>
  );
};

import { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import AgilePageLayout from '../AgilePageLayout'

const Paragraph = styled.p`
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: 700;

  text-align: center;

  padding: 0 2rem;

  & ~ button {
    background: #ff0000;
    border-radius: 1rem;
    color: #ffffff;

    font-size: 1.4rem;
    font-weight: 700;

    width: 80vw;
    max-width: 60rem;

    margin-top: 2rem;
    padding: .8rem;
  }
`

type ErrorProps = {
  text: string,
  goBack?: () => void
}

function Error ({ text, goBack }: ErrorProps) {
  const errorPortal = document.getElementById('error_portal')

  const { search } = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    navigate(`/${search}`)
  }, [])

  if (!errorPortal) return null

  return ReactDOM.createPortal((
    <AgilePageLayout noGoBack>
      <Paragraph>{text}</Paragraph>
      {
        goBack && (
          <button
            type="button"
            onClick={goBack}
          >
            Voltar
          </button>
        )
      }
    </AgilePageLayout>
  ), errorPortal)
}
export default Error

import { Ticket } from 'lucide-react'
import { ChangeEvent } from 'react'
import styled from 'styled-components'
import Field from '../../../components/Field'

type VoucherProps = {
  voucher: string,
  change: (e: ChangeEvent<HTMLInputElement>) => void,
  clear: () => void,
  action: () => void
}

const VoucherContainer = styled.div`
  display: flex;
  align-items: center;

  padding: .8rem;

  > button {
    background: #ff0000;
    border-radius: 1rem;
    color: #ffffff;

    font-size: 1.4rem;
    font-weight: 700;

    padding: 1rem 1rem;
    margin-left: 1rem;
  }
`

function Voucher ({ voucher, change, clear, action }: VoucherProps) {
  const fieldProps = {
    value: voucher,
    change,
    placeholder: 'Inserir Cupom/Voucher',
    clear
  }

  return (
    <VoucherContainer>
      <Field fieldProps={fieldProps}>
        <Ticket size={24} color="#ff0000" />
      </Field>
      <button type="button" onClick={action}>Usar</button>
    </VoucherContainer>
  )
}

export default Voucher

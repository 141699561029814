import axios from 'axios'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import OverlayNotification from '../../components/OverlayNotification'
import ScaleLoader from '../../components/ScaleLoader'
import { RootState } from '../../store/store'
import { Request } from '../../types/requests'
import { StoreParams } from '../../types/storeInfo'
import EmptyList from './components/EmptyList'
import Header from './components/Header'
import RequestCard from './components/RequestCard'
import StoreCard from './components/StoreCard'
import { RequestsContainer } from './styles'

function Requests () {
  const { info } = useSelector((state: RootState) => state.info)
  const { accessToken, selectedAddress } = useSelector((state: RootState) => state.user)
  const { storeParams } = useSelector((state: RootState) => state.token)

  const [requests, setRequests] = useState<Request[]>([])
  const [stores, setStores] = useState<StoreParams[]>([])
  const [isRequestsLoading, setIsRequestsLoading] = useState<boolean>(false)
  const [isStoresLoading, setIsStoresLoading] = useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const { search } = useLocation()

  const navigate = useNavigate()

  async function searchStores() {
    const env = process.env.REACT_APP_ACCOUNT_STORES_URI

    if (!env || !storeParams.api) return

    setIsStoresLoading(true)
    
    try {
      const response = await axios({
        method: 'POST',
        url: `${storeParams.api}/${env}`,
        data: selectedAddress || info.Endereco,
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      })

      setStores(response.data)
      setIsStoresLoading(false)
    } catch (err) {
      setIsStoresLoading(false)
    }
  }
  
  async function searchRequests() {
    const env = process.env.REACT_APP_REQUESTS_URI

    if (!env || !storeParams.api) {
      return setError('Algum erro ocorreu durante a busca dos pedidos, tente novamente mais tarde.')
    }

    setIsRequestsLoading(true)
    
      try {
        const response = await axios({
          method: 'POST',
          url: `${storeParams.api}/${env}`,
          data: selectedAddress || info.Endereco,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        })

        setRequests(response.data)
        setIsRequestsLoading(false)
      } catch (err) {
        setIsRequestsLoading(false)
      }
  }

  function refresh() {
    searchRequests()
    searchStores()
  }

  useEffect(() => {
    if (!accessToken) return

    searchRequests()
  }, [accessToken, selectedAddress])

  useEffect(() => {
    if (!accessToken) return

    searchStores()
  }, [accessToken, selectedAddress])

  return (
    <RequestsContainer>
      <Header goBack={() => navigate(`/loja/cardapio${search}`)} refresh={refresh} />
      {
        (isRequestsLoading || isStoresLoading)
          ? <ScaleLoader />
          : requests.length > 0
            ? (
              <div className="page_context">
                {
                  stores.length > 0 && (
                    <div className="stores">
                      <h2>Lojas mais pedidas</h2>
                      <div className="store_listing">
                        {stores.map((store) => {
                          const photoParam = store.Parametros.find((param) => param.Chave === 'MV_HOST_FOTO_LOJA')?.Valor
                          const image = photoParam
                            ? `${photoParam}/${info.Sigla}1.jpg`
                            : undefined

                          return (
                            <StoreCard
                              key={store.Id}
                              name={store.Nome}
                              image={image}
                            />
                          )
                        })}
                      </div>
                    </div>
                  )
                }
                <div className="items">
                  <h2>Histórico</h2>
                  <div className="item_list">
                    {requests.map((request) => <RequestCard key={request.CdPed} request={request} />)}
                  </div>
                </div>
              </div>
            )
            : (
              <EmptyList />
            )
      }
      {
        error && (
          <OverlayNotification text={error} close={() => navigate(`/loja/cardapio${search}`)} />
        )
      }
    </RequestsContainer>
  )
}

export default Requests

import { X } from 'lucide-react'
import { ChangeEvent } from 'react'
import { ErrorType } from '../../../hooks/useFormError'

type FormFieldProps = {
  value: string,
  change: (e: ChangeEvent<HTMLInputElement>) => void,
  placeholder: string,
  name: string,
  type?: string,
  clear: (field: string) => void,
  error: ErrorType | undefined
}

function FormField ({ value, change, type, name, placeholder, clear, error }: FormFieldProps) {
  return (
    <div>
      <label className={error ? 'error' : undefined}>
        <input
          placeholder={placeholder}
          value={value}
          onChange={change}
          name={name}
          type={type}
        />
        <button type="button" onClick={() => clear(name)}>
          <X size={24} color="#aaaaaa" />
        </button>
      </label>
      { error?.message && <span>{error.message}</span> }
    </div>
  )
}

export default FormField

import { appIcons } from '../assets/icons'

export function getSizeImage (type: string) {
  const { pizza, appetizer, softDrink, dessert, wineBottle, combo, hamburger, defaultSize } = appIcons

  switch (type) {
    case 'PIZZA':
      return pizza

    case 'ENTRADAS':
      return appetizer

    case 'ACOMPANHAMENTOS':
      return appetizer

    case 'BEBIDAS':
      return softDrink

    case 'SOBREMESAS':
      return dessert

    case 'VINHO':
      return wineBottle

    case 'COMBOS':
      return combo

    case 'LANCHES':
      return hamburger

    case 'SMASH':
      return hamburger

    default:
      return defaultSize
  }
}

import { useSelector } from 'react-redux'
import styled from 'styled-components'

import orderFood from '../../../assets/icons/order-food.png'
import { RootState } from '../../../store/store'

const EmptyListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 16rem;

  .image {
    width: 40vw;
    max-width: 250px;

    margin-bottom: 2rem;

    img {
      width: 100%;
    }
  }

  p {
    color: #464646;

    font-size: 1.2rem;
    font-weight: 700;

    text-align: center;
  }
`

function EmptyList() {
  const { selectedAddress } = useSelector((state: RootState) => state.user)
  
  return (
    <EmptyListContainer>
      <div className="image">
        <img src={orderFood} alt="Ícone de pedido." />
      </div>
      <p>Infelizmente você não tem nenhum pedido neste APP {selectedAddress && `na cidade de ${selectedAddress.Cid?.d}/${selectedAddress.Uf}`}</p>
    </EmptyListContainer>
  )
}

export default EmptyList

import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { RootState } from '../../../store/store'
import { cardapioItem } from '../../../types/cardapioItem'
import { Request } from '../../../types/requests'
import { capitalizeEachWord } from '../../../utils/capitalizeEachWord'
import { getRequestInfo } from '../../../utils/getRequestInfo'

type RequestCardProps = {
  request: Request
}

const RequestCardContainer = styled.div`
  border: 1px solid #cccccc;
  border-radius: 1rem;
  color: #464646;

  width: 90%;

  font-size: 1.3rem;

  padding: 1.4rem;
  margin-bottom: 1.8rem;

  box-shadow: 3px 3px 9px -8px rgb(0 0 0 / 52%);
  pointer-events: none;

  .request_info {
    display: flex;

    .status {
      flex: 1;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      margin-right: 1rem;

      img {
        width: 60%;

        margin-bottom: 1rem;
      }

      p {
        font-weight: 700;
      }
    }

    .description {
      flex: 2;
    }

    p {
      font-size: 1.2rem;
      margin: .2rem 0;
    }

    .price, .method {
      font-weight: 700;
    }

    .price {
      color: #00a989;
    }

    .method {
      color: #ffc409;
    }
  }

  .store_info {
    display: flex;
    align-items: center;

    border-bottom: 1px solid #cccccc;

    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .icon {

      width: 4rem;
      height: 4rem;

      margin-right: 1rem;

      img {
        border-radius: .4rem;

        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
      }
    }

    .name {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }

  @media (min-width: 646px) {
    width: 45%;
    margin-right: 7rem;
    margin-bottom: 3.2rem;

    &:nth-child(2n) {

    }
  }
`

function RequestCard({ request }: RequestCardProps) {
  const { storeParams } = useSelector((state: RootState) => state.token)

  const items = JSON.parse(request.Itens)

  const splittedDate = request.DataHora.split('T')
  const date = splittedDate[0].split('-').reverse().join('/')

  const splittedHour = splittedDate[1].split('.')[0].split(':')
  const hour = `${splittedHour[0]}:${splittedHour[1]}`

  const requestInfo = getRequestInfo(request.Evento)

  return (
    <RequestCardContainer>
      <div className="store_info">
        <div className="icon">
          <img src={`${storeParams.store}/${request.Sigla}1.jpg`} alt="Ícone da loja" />
        </div>
        <div className="name">{request.NomeLoja}</div>
      </div>
      <div className="request_info">
        <div className="status">
          <img src={requestInfo[0]} alt="Ícone do pedido" />
          <p>{requestInfo[1]}</p>
        </div>
        <div className="description">
          <strong>{request.Tipo}</strong>
          <p><strong>Pedido:</strong> {request.CdPed}</p>
          <p><strong>Valor:</strong> <span className="price">R$ {request.ValorFechamento.toFixed(2)}</span></p>
          <p><strong>Forma:</strong> <span className="method">{request.FormaBrand}</span></p>
          <p><strong>Data:</strong> {date} {hour}</p>
          {
            items.map((item: any) => (
              item.Sabores.map((taste: cardapioItem) => (
                <p key={taste.Codigo}><strong>({item.Quantidade}) {capitalizeEachWord(taste.Nome)}</strong></p>
              ))
            ))
          }
        </div>
      </div>
    </RequestCardContainer>
  )
}
export default RequestCard

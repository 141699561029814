import styled from 'styled-components';

export const PromotionContainer = styled.div`
      display: flex;
      justify-content: flex-end;
     
      .not-promotional-card {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        text-align: center;
        white-space: nowrap;
      }
      
      .red-cross{
        position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0.6;
      }
    
      .promotional-card{
        white-space: nowrap;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2f760c;
        color: #fff;
        padding: 3px;
        border-radius: 3px;
      
      }
      .price {
        position: relative;
      }
      span {
        font-size: 1.2rem;
        font-weight: bolder;

        &.old {
          position: relative;
          color: #ff0000;
          margin-right: 1.2rem;
        }
        &.new{
          color: #2f760c;
        }
      }

      .amplified {
        font-size: 1.4rem;
      }
    }


`;

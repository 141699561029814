import { Addon, Addons } from '../../../../types/cardapioItem'
import { capitalizeEachWord } from '../../../../utils/capitalizeEachWord'
import Complement from './Complement'
import { ComplementContainerGroup } from './styles'

type ComplementContainerProps = {
  addon: Addons,
  setComplement: (name: string, arg: Addon) => void,
  quant: number,
  isFieldChecked: (item: string, addon: string) => boolean
}

function ComplementContainer ({ addon, quant, setComplement, isFieldChecked }: ComplementContainerProps) {
  return (
    <ComplementContainerGroup key={addon.Nome}>
      <div className="section_header">
        <p>{capitalizeEachWord(addon.Nome)} <span>(Escolha de {addon.QtdeMinima} a {addon.QtdeMaxima})</span> </p>
        { addon.QtdeMinima >= 1 && <p className="required">OBRIGATÓRIO</p>}
      </div>
      <div className="options">
        {
          addon.Itens.map((item) => (
            <Complement
              key={item.Codigo}
              info={{
                Nome: item.Nome,
                Preco: item.Preco,
                Codigo: item.Codigo,
                Quantidade: item.Quantidade
              }}
              type={addon.Nome}
              setComplement={setComplement}
              qtd={{
                min: addon.QtdeMinima,
                max: addon.QtdeMaxima
              }}
              currentQuant={quant}
              checked={() => isFieldChecked(item.Nome, addon.Nome)}
            />
          ))
        }
      </div>
    </ComplementContainerGroup>
  )
}
export default ComplementContainer

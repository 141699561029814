import { Route, Routes } from 'react-router-dom'
import Cardapio from '../pages/Cardapio'
import Cart from '../pages/Cart'
import Home from '../pages/Home'
import Info from '../pages/Info'
import Login from '../pages/Sign'
import Loja from '../pages/Loja'
import ProductDetails from '../pages/ProductDetails'
import SearchProducts from '../pages/SearchProducts'
import Address from '../pages/Address'
import LoginProtectedRoute from './LoginProtectedRoute'
import Requests from '../pages/Requests'
import RestorePassword from '../pages/RestorePassword'

function Router () {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='/endereco' element={<Address />} />
      <Route path='/login' element={<Login />} />
      <Route path='/restaurarSenha' element={<RestorePassword />} />
      <Route path='/loja' element={<Loja />}>
        <Route path="cardapio" element={<Cardapio />} />
        <Route path="informacoes" element={<Info />} />
      </Route>
      <Route path='/pesquisa' element={<SearchProducts />} />
      <Route path='/loja/cardapio/:codigoProduto' element={<ProductDetails />} />
      <Route path='/' element={<LoginProtectedRoute />}>
        <Route path='/carrinho/entrega' element={<Cart currentCart="delivery" />} />
        <Route path='/carrinho/retirada' element={<Cart currentCart="withdraw" />} />
        <Route path='/carrinho/drive' element={<Cart currentCart="drive" />} />
        <Route path='/pedidos' element={<Requests />} />
      </Route>
    </Routes>
  )
}
export default Router

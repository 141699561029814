import ReactDOM from 'react-dom'
import { Plus } from 'lucide-react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Overlay from '../../../components/Overlay'
import { RootState } from '../../../store/store'
import { useLocation, useNavigate } from 'react-router-dom'
import { setAddress } from '../../../store/features/user/userSlice'
import { AddressSelectorContainer } from '../styles'
import { AddressType } from '../../../types/address'

type AddressSelectorProps = {
  close: () => void
}

function AddressSelector ({ close }: AddressSelectorProps) {
  const addressSelectorPortal = document.getElementById('address_selector_portal')

  const overlayRef = useRef<HTMLDivElement>(null)

  const { userData, selectedAddress } = useSelector((state: RootState) => state.user)

  const { search } = useLocation()

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const [optionValue, setOptionValue] = useState<string>(() => {
    if (selectedAddress.Log) {
      return `${selectedAddress.Log.toUpperCase()}, ${selectedAddress.Numero}`
    }

    return ''
  })

  function handleOptionChange (e: ChangeEvent<HTMLInputElement>) {
    setOptionValue(e.target.value)

    localStorage.setItem(`selected_address_${userData.unique_name}`, e.target.value)
    dispatch(setAddress(e.target.value))

    close()
  }

  function handleAddNewAddress () {
    navigate(`/endereco${search}`)
  }

  function handleDocumentTouch (e: TouchEvent | MouseEvent) {
    if (e.target === overlayRef.current) {
      close()
    }
  }

  useEffect(() => {
    document.addEventListener('touchstart', handleDocumentTouch)
    document.addEventListener('mousedown', handleDocumentTouch)

    return () => {
      document.removeEventListener('touchstart', handleDocumentTouch)
      document.removeEventListener('mousedown', handleDocumentTouch)
    }
  }, [])

  if (!addressSelectorPortal) return null

  const offlineAddress = localStorage.getItem('offline_address')

  const addresses: AddressType[] | undefined =
    userData.CompleteUser
      ? userData.CompleteUser.Usuario.Enderecos
      : offlineAddress ? ([JSON.parse(offlineAddress)]) : undefined

  return ReactDOM.createPortal((
    <Overlay position="bottom" ref={overlayRef}>
      <AddressSelectorContainer className="page_context">
        <h3>SELECIONE UM ENDEREÇO</h3>
        <div className="options">
          {
            addresses && addresses.map((address: AddressType) => (
              <label key={`${address.Log.toUpperCase()}, ${address.Numero}`}>
                <input
                  id={address.Id}
                  type="radio"
                  name="select_address"
                  value={`${address.Log.toUpperCase()}, ${address.Numero}`}
                  onChange={handleOptionChange}
                  checked={optionValue === `${address.Log.toUpperCase()}, ${address.Numero}`}
                />
                <div className="address_description">
                  <p>{address.Log.toUpperCase()}, {address.Numero}</p>
                  <p>{address.Cid.d}/{address.Uf}</p>
                </div>
              </label>
            ))
          }
        </div>
        <button
          type="button"
          onClick={handleAddNewAddress}
          className="add_new_address_button"
        >
          <Plus size={18} />
          Adicionar novo endereço
        </button>
      </AddressSelectorContainer>
    </Overlay>
  ), addressSelectorPortal)
}

export default AddressSelector

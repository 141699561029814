import redxis from '../../assets/icons/redxis.png';
import { ItemOnCart } from '../../types/cart';
import { PromotionContainer } from './styles';

type PriceCardProps = {
  PrecoReferencia: number,
  Preco: number,
};

function PriceCard({ PrecoReferencia, Preco  }: PriceCardProps) {

  const isPromo = PrecoReferencia > Preco;

  return (
    <PromotionContainer>
      {isPromo && (
        <div className='not-promotional-card'>
          <img src={redxis} width={30} height={30} className='red-cross' />
          <span className='old'>
            De:{' '}
            <span className='amplified'>R$ {PrecoReferencia.toFixed(2)}</span>
          </span>
        </div>
      )}

      <div className='promotional-card'>
        <span>
          Por:{' '}
          <span className='amplified'>R$ {Preco.toFixed(2)}</span>
        </span>
      </div>
    </PromotionContainer>
  );
}

export default PriceCard;

import { complementIcons } from '../assets/icons'

export function getComplementImage (itemName: string) {
  let imageIcon

  if (itemName.includes('GLUTEN')) {
    imageIcon = complementIcons.gluten
  } else if (itemName.includes('LOWCARB')) {
    imageIcon = complementIcons.lowCarbDiet
  } else if (itemName.includes('LACTOSE')) {
    imageIcon = complementIcons.lactose
  } else if (itemName.includes('VEGANA')) {
    imageIcon = complementIcons.vegana
  } else if (itemName.includes('INTEGRAL')) {
    imageIcon = complementIcons.integral
  } else if (itemName.includes('TRADICIONAL')) {
    imageIcon = complementIcons.tradicional
  } else {
    imageIcon = complementIcons.border
  }

  return imageIcon
}

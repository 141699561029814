import { ReactNode } from 'react'
import { CartItemsSection, SectionHeader } from '../styles'

type CartSectionProps = {
  title: string,
  children?: ReactNode
}

function CartSection ({ title, children }: CartSectionProps) {
  return (
    <CartItemsSection className="page_context">
      <SectionHeader>
        <h3>{title}</h3>
      </SectionHeader>
      {children}
    </CartItemsSection>
  )
}
export default CartSection

import { ArrowLeft, RefreshCw } from 'lucide-react'
import styled from 'styled-components'

const HeaderContainer = styled.header`
  height: 5rem;

  display: flex;
  justify-content: space-between;

  margin: 0 auto .6rem;
  padding: 0 2rem;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

type HeaderProps = {
  goBack: () => void,
  refresh: () => void
}

function Header ({ goBack, refresh }: HeaderProps) {
  return (
    <HeaderContainer className="page_context">
      <button type="button" onClick={goBack}>
        <ArrowLeft color="#464646" size={24} />
      </button>
      <button type="button" onClick={refresh}>
        <RefreshCw color="#999999" size={24} />
      </button>
    </HeaderContainer>
  )
}

export default Header

import { configureStore } from '@reduxjs/toolkit'
import tokenReducer from './features/token/tokenSlice'
import cardapioReducer from './features/cardapio/cardapioSlice'
import infoReducer from './features/info/infoSlice'
import itemReducer from './features/item/itemSlice'
import cartReducer from './features/cart/cartSlice'
import userReducer from './features/user/userSlice'
import notificationReducer from './features/notification/notificationSlice'

export const store = configureStore({
  reducer: {
    token: tokenReducer,
    cardapio: cardapioReducer,
    info: infoReducer,
    item: itemReducer,
    cart: cartReducer,
    user: userReducer,
    notification: notificationReducer
  }
})

export type RootState = ReturnType<typeof store.getState>

import { CornerLeftUp } from 'lucide-react'
import { useEffect, useRef } from 'react'
import ReactDOM from 'react-dom'
import { useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import { PageNavigationContainer, PageNavigationOverlay } from '../styles'

type PageNavigationProps = {
  closeNavigation: () => void,
}

function PageNavigation ({ closeNavigation }: PageNavigationProps) {
  const pageNavigationModal = document.getElementById('page_navigation_modal')

  const { categories } = useSelector((state: RootState) => state.cardapio)

  const overlayRef = useRef<HTMLDivElement>(null)

  function handleCloseNavigation (e: MouseEvent) {
    if (e.target === overlayRef.current) closeNavigation()
  }

  useEffect(() => {
    document.addEventListener('click', handleCloseNavigation)

    return () => {
      document.removeEventListener('click', handleCloseNavigation)
    }
  }, [handleCloseNavigation])

  if (!pageNavigationModal) return null

  return ReactDOM.createPortal((
    <PageNavigationOverlay ref={overlayRef}>
      <PageNavigationContainer className="page_context">
        <h3>ESCOLHA UMA CATEGORIA</h3>
        <ul onClick={closeNavigation}>
          {
            categories.map((category) => (
              <li key={category}><a href={`#${category}`}>{category}</a></li>
            ))
          }
          <li><a href="#">
            <CornerLeftUp size={24} color="#ff0000" />
            <span>Voltar ao Topo</span>
          </a></li>
        </ul>
      </PageNavigationContainer>
    </PageNavigationOverlay>
  ), pageNavigationModal)
}

export default PageNavigation

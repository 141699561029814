import { useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import Notifier from './components/Notifier'
import { RootState } from '../../store/store'
import AgilePageLayout from '../AgilePageLayout'

function LoginProtectedRoute () {
  const { accessToken } = useSelector((state: RootState) => state.user)

  const { search } = useLocation()

  const navigate = useNavigate()

  if (accessToken) {
    return <Outlet />
  } else {
    return (
      <AgilePageLayout goBack={() => navigate(-1)}>
        <Notifier goToLoginScreen={() => navigate(`/login${search}`)} />
      </AgilePageLayout>
    )
  }
}

export default LoginProtectedRoute

export function capitalizeEachWord (phrase: string | undefined) {
  if (!phrase) return ''

  const words = phrase.toLowerCase().trim().split(' ')
  const finalCapitalizedArray: string[] = []

  for (const i of words) {
    finalCapitalizedArray.push(i.charAt(0).toUpperCase() + i.slice(1).toLowerCase())
  }

  return finalCapitalizedArray.join(' ')
}

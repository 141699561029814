import { ChangeEvent, useEffect, useState } from 'react'
import styled from 'styled-components'
import SearchBar from '../../../components/SearchBar'
import { VoucherItemType } from '../../../types/cart'
import VoucherCard from './VoucherCard'

type VoucherSelectionProps = {
  vouchers: VoucherItemType[],
  close: () => void,
  setVoucher: (voucher: string) => void,
  action: (voucherData?: string) => void
}

const VoucherSelectionContainer = styled.div`
  background: #ffffff;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;

  .voucher_list {
    display: flex;
    flex-direction: column;

    .voucher_card {
      color: #3c916a;

      display: flex;
      align-items: center;

      font-size: 1.2rem;
      font-weight: 700;

      padding: 1rem .6rem;

      border-bottom: 1px solid #cccccc;

      .icon {
        margin-right: 2rem;
      }

      .description {
        text-align: left;

        .code {
          margin-bottom: .6rem;
        }
      }
    }
  }

  .warning {
    font-size: 1.4rem;
    font-weight: 700;

    text-align: center;

    margin-top: 30rem;
  }
`

function VoucherSelection({ vouchers, close, setVoucher, action }: VoucherSelectionProps) {
  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    const htmlBody = document.querySelector('body')
    if (!htmlBody) return

    const topPos = window.scrollY

    htmlBody.style.position = 'fixed'
    htmlBody.style.top = `-${topPos}px`

    return () => {
      const oldTopPos = htmlBody.style.top
      htmlBody.style.position = ''
      htmlBody.style.top = ''
      window.scrollTo(0, parseInt(oldTopPos || '0') * -1)
    }
  }, [])

  const filteredVouchers = vouchers.filter((voucher) => voucher.VOUCHER_ID.toUpperCase().includes(searchValue.toUpperCase()))

  function selectVoucher (voucher: string) {
    setVoucher(voucher)
    action(voucher)
  }

  return (
    <VoucherSelectionContainer>
      <SearchBar
        searchValue={searchValue}
        handleGoBack={close}
        handleChangeSearchValue={(e: ChangeEvent<HTMLInputElement>) => setSearchValue(e.target.value)}
        cleanSearchValue={() => setSearchValue('')}
        placeholder="Pesquise pelo código do voucher..."
      />
      {
        filteredVouchers.length > 0
        ? (
          <div className="voucher_list">
            {
              filteredVouchers.map((voucher) => (
                <VoucherCard key={voucher.VOUCHER_ID} code={voucher.VOUCHER_ID} price={voucher.VALOR} action={() => selectVoucher(voucher.VOUCHER_ID)} />
              ))
            }
          </div>
        )
        : (
          <div className="warning">
            <p>Nenhum voucher encontrado.</p>
          </div>
        )
      }
    </VoucherSelectionContainer>
  )
}

export default VoucherSelection

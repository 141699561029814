import styled from 'styled-components'

export const SearchProductsContainer = styled.div`
  height: 100vh;

  display: flex;
  flex-direction: column;

  .search_condition_view {
    position: relative;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 0 1.4rem;

    text-align: center;

    flex: 1;

    p {
      color: #464646;
      font-size: 1.4rem;
      font-weight: bold;
    }
  }  

`

export const SearchBarContainer = styled.div`
  background: #8A2BE2;

  display: flex;

  padding: .8rem 0;

  input {
    flex: 1;
    border: 0;
    border-radius: .8rem;

    padding: 0 1rem;
  }

  .icon-wrapper {
    background: 0;

    padding: .4rem 1.2rem;
  }
`

import styled from 'styled-components'

type HomeContainerTypes = {
  background: string,
  color: string
}

export const HomeContainer = styled.main<HomeContainerTypes>`
  background-color: #${({ color }) => color};
  background-image: url(${({ background }) => background});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  height: 100vh;

  @media (max-width: 890px) {
    background-size: cover;
  }

  .element_context_wrapper {
    width: min(1000px, 86vw);
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    margin: 0 auto;
    padding-bottom: 8rem;
    
    span {
      color: #ffffff;

      font-size: 1.4rem;
      font-weight: 700;

      margin-top: 1rem;
    }
  }

  button {
    background: ${({ theme }) => theme.home.colors.button.background};
    border-radius: 1.4rem;
    color: ${({ theme }) => theme.home.colors.button.text};

    width: 100%;
    
    font-size: 2.4rem;
    font-weight: 700;
    letter-spacing: .8px;

    padding: 1rem;

    box-shadow: 0px 0px 9px 4px rgba(0,0,0,0.12);
  }
`

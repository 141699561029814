import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'
import { MapPin, User } from 'lucide-react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import Estimate from '../../../components/Estimate'
import { RootState } from '../../../store/store'
import { AddressType } from '../../../types/address'
import { DeliveryTime } from '../../../types/storeInfo'
import { capitalizeEachWord } from '../../../utils/capitalizeEachWord'

type AddressSectionProps = {
  method: 'withdraw' | 'delivery' | 'drive',
  placePosition: AddressType,
  timestampsProps: {
    timestamps: DeliveryTime[],
    selectedDeliveryTime: DeliveryTime,
    setSelectedTimestamps: (timestamp: string) => void,
  }
}

const AddressSectionContainer = styled.section`
  padding: 2rem .4rem;

  .address {
    border-radius: 1rem;

    display: flex;

    min-height: 15rem;

    box-shadow: 0px 0px 5px -1px #333333;

    .map_wrapper {
      flex: 1;

      margin: 1.2rem .8rem;
    }

    .information {
      margin: 1.2rem .8rem;

      padding-right: 1rem;
    }

    .title {
      display: flex;
      align-items: center;

      margin-bottom: 1.6rem;
    }

    .address_description {
      display: flex;
      align-items: center;

    }

    p, span, h4 {
      color: #888888;
      font-size: 1.2rem;
      font-weight: 700;

      line-height: 2rem;
    }

    h4 {
      color: #880000;
    }

    svg {
      margin-right: 1rem;
    }
    
    @media (max-width: 360px) {
      .address_description {
        flex-direction: column;

        text-align: center;
      }

      #map_pin {
        margin-bottom: .6rem;
      }
    }

    @media (max-width: 320px) {
      flex-direction: column;

      .map_wrapper {
        min-height: 10rem;

        display: flex;
        flex-direction: column;
        
        div {
          flex: 1;
        }
      }

      .title {
        justify-content: center;
      }
    }
  }

  .timestamp_options {
    margin-top: 2rem;
    
    select {
      color: #555555;

      border: 0;
      border-radius: .6rem;

      font-size: 1.4rem;
      font-weight: 700;

      width: 100%;

      padding: .8rem;

      box-shadow: 0px 0px 5px -1px #333333;

      &:focus {
        outline: 1px solid #666666;
      }
    }

    p {
      color: #555555;

      font-size: 1.4rem;
      font-weight: 700;

      margin-bottom: 1.2rem;
    }
  }
`

function AddressSection ({ method, placePosition, timestampsProps }: AddressSectionProps) {
  const { userData } = useSelector((state: RootState) => state.user)
  const { info } = useSelector((state: RootState) => state.info)

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY || ''
  })

  const mapPos = {
    lat: placePosition.Location?.Latitude,
    lng: placePosition.Location?.Longitude
  }

  return (
    <AddressSectionContainer>
      <div className="address small_context">
        <div className="map_wrapper">
          {
            isLoaded && placePosition && mapPos.lat && mapPos.lng && (
              <GoogleMap
                mapContainerStyle={{ width: '100%', height: '100%' }}
                center={mapPos}
                zoom={15}
              >
                <Marker position={mapPos} />
              </GoogleMap>
            )
          }
        </div>
        <div className="information">
          <div className="title">
            {
              (method === 'withdraw' || method === 'drive')
                ? (
                    <h4>{capitalizeEachWord(info.Nome)}</h4>
                  )
                : (
                    <>
                      <User size={20} color="#880000" />
                      <h4>{capitalizeEachWord(userData.CompleteUser.Usuario.Apelido)}</h4>
                    </>
                  )
            }
          </div>
          <div className="address_description">
            <MapPin id="map_pin" color="#ffff00" size={20} />
            <div>
              <p>
                {capitalizeEachWord(placePosition.Log)}
                {placePosition.Numero && <span>, {placePosition.Numero}</span>}
              </p>
              <p>{capitalizeEachWord(placePosition.Bai?.d)}</p>
              <p>{capitalizeEachWord(placePosition.Complemento)}</p>
              <p>
                {`
                   ${capitalizeEachWord(placePosition.Cid?.d)}/${placePosition.Uf?.toUpperCase()}
                `}
              </p>
            </div>
          </div>
        </div>
      </div>
      <Estimate />
      <div className="timestamp_options small_context">
        <p>Horário</p>
        <select
          name="timestamps"
          value={timestampsProps.selectedDeliveryTime.Horario}
          onChange={(e) => timestampsProps.setSelectedTimestamps(e.target.value)}
        >
          {
            timestampsProps.timestamps.map((timestamp) => (
              <option
                key={timestamp.Horario + timestamp.DiaSemana}
                value={timestamp.Horario}
              >
                {timestamp.Horario}
              </option>
            ))
          }
        </select>
      </div>
    </AddressSectionContainer>
  )
}

export default AddressSection



// Inicia tracking do facebook
export const InitiateFacebookTracking = () => {
  
    (function (f: any, b: any, e: any, v: any, n: any, t: any, s: any) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(
      window,
      document,
      'script',
      'https://connect.facebook.net/en_US/fbevents.js',
      undefined,
      undefined,
      undefined
    );
    (window as any).fbq('init', '410923781571938');
    (window as any).fbq('track', 'PageView');

  return null;
};

// Evento de tracking ao adicionar ao carrinho
export const AddToCartTracking = () => {
  (window as any).fbq('track', 'addToCart');
};

//Evento de tracking ao mudar forma de pagamento no carrinho
export const InitiateCheckoutTracking = () => {
  (window as any).fbq('track', 'InitiateCheckout');
};

//Evento de tracking ao finalizar compra no carrinho
export const InitiatePurchaseTracking = (purchaseTotal: number) => {
  (window as any).fbq('track', 'Purchase', {
    currency: 'BRL',
    value: purchaseTotal
  });
};


import axios from 'axios'
import { TokenRequestBody } from '../../../types/authApi'

async function getToken (body: TokenRequestBody) {
  const env = process.env.REACT_APP_LICENSE_URI

  if (!env || !body) return

  const response = await axios({
    method: 'POST',
    data: body,
    url: env
  })

  return response
}

export const tokenService = {
  getToken
}

import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store/store';
import { ItemCardapio } from '../../../../types/cardapio';
import redxis from '../../../../assets/icons/redxis.png';
import PriceCard from '../../../../components/PriceCard/Index';

type HalfItemButtonProps = {
  item: ItemCardapio;
  action: (arg: string) => void;
};

function HalfItemButton({ item, action }: HalfItemButtonProps) {
  const { Codigo, Nome, ReceitaCurta, PrecoReferencia, Preco } =
    item;

  const { storeParams } = useSelector((state: RootState) => state.token);

  const [isImageError, setIsImageError] = useState<boolean>(false);

  return (
    <button
      className='item_card'
      key={Codigo}
      onClick={() => action(Nome)}
    >
      {!isImageError && (
        <div className='image'>
          <img
            src={`${storeParams.item}/${Codigo}.jpg`}
            onError={() => setIsImageError(true)}
          />
        </div>
      )}
      <div className='item_description'>
        <p className='title'>{Nome}</p>
        <p className='more'>{ReceitaCurta}</p>
       
       <PriceCard Preco={Preco} PrecoReferencia={PrecoReferencia} />
      </div>
    </button>
  );
}

export default HalfItemButton;

import { X } from 'lucide-react'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Additional, Addon, Suggestion } from '../../../types/cardapioItem'
import Footer from './Footer'
import { ItemOptionsContainer } from '../styles'
import SizeSelector from './SizeSelector'
import Complements from './Complements/Complements'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../../store/store'
import Ingredients from './Ingredients/Ingredients'
import Additionals from './Additionals/Additionals'
import HalfSelector from './HalfSelector'
import { ItemCardapio } from '../../../types/cardapio'
import axios from 'axios'
import ScaleLoader from '../../../components/ScaleLoader'
import { clearItem, resetSize, setItemComplement, toggleHalfItemAdditional, toggleItemAdditional, toggleItemIngredient } from '../../../store/features/item/itemSlice'
import { ItemOnCart, Prices } from '../../../types/cart'
import { v4 } from 'uuid'
 import { addItem } from '../../../store/features/cart/cartSlice'
import { setNotification } from '../../../store/features/notification/notificationSlice'
import OverlayNotification from '../../../components/OverlayNotification'
import { useLocation, useNavigate } from 'react-router-dom'
import Suggestions from './Suggestions/Suggestions'
import Notification from '../../../components/Notification'
import { AddToCartTracking } from '../../../monitoring/facebook';
import { TrackingValidator } from '../../../monitoring/TrackingValidator'

type ItemOptionsProps = {
  codigo: number,
  setHalf: (item: ItemCardapio | undefined) => void,
}

function ItemOptions ({ codigo, setHalf }: ItemOptionsProps) {
  const { search } = useLocation()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  const firstRender = useRef<boolean>(true)

  const { item, halfItem } = useSelector((state: RootState) => state.item)
  const { info } = useSelector((state: RootState)=> state.info);
  const { type } = useSelector((state: RootState) => state.cardapio)
  const { tokenData, storeParams } = useSelector((state: RootState) => state.token)
  const { notification } = useSelector((state: RootState) => state.notification)

  const [currentSizePos, setCurrentSizePos] = useState<number>(0)

  const currentSize = item.Tamanhos[currentSizePos]
  const halfItemSize = halfItem?.Tamanhos.find((size) => size.Codigo === currentSize.Codigo)

  const [productType, setProductType] = useState<'normal' | 'meio a meio'>('normal')
  const [observations, setObservations] = useState<string>('')

  const [halfItems, setHalfItems] = useState<ItemCardapio[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [isAdditionalsSectionOpen, setIsAdditionalsSectionOpen] = useState<boolean>(false)
  const [isHalfAdditionalsSectionOpen, setIsHalfAdditionalsSectionOpen] = useState<boolean>(false)

  const [prices, setPrices] = useState<Prices>()
  const [isPriceLoading, setIsPriceLoading] = useState<boolean>(false)

  const [suggestions, setSuggestions] = useState<Suggestion[]>()
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState<boolean>(false)
  const [isSuggestionsOpen, setIsSuggestionsOpen] = useState<boolean>(false)

  const [error, setError] = useState<string>('')

  const [quant, setQuant] = useState<number>(1)

  function chooseSize (sizePos: number) {
    const newSize:any = item.Tamanhos[sizePos]

    if (!newSize) return

   
    setPrices({
      Preco: newSize.Preco,
      PrecoReferencia: newSize.PrecoReferencia,
      ValorComDesconto: newSize.ValorComDesconto,
      ValorDesconto: newSize.ValorDesconto
    })
   

    setCurrentSizePos(sizePos)
  }

  function setIngredient (ingredientCode: number) {
    dispatch(toggleItemIngredient(ingredientCode))
  }

  function setAdditional (additional: Additional) {
    const toggleItemAdditionalProps = {
      sizeCode: currentSize.Codigo,
      additional
    }

    dispatch(toggleItemAdditional(toggleItemAdditionalProps))
  }

  function setHalfAdditional (additional: Additional) {
    if (!halfItemSize) return

    const setHalfAddProps = {
      sizeCode: halfItemSize.Codigo,
      additional
    }

    dispatch(toggleHalfItemAdditional(setHalfAddProps))
  }

  function handleChangeObservation (e: ChangeEvent<HTMLTextAreaElement>) {
    setObservations(e.target.value)
  }

  function cleanObservation () {
    setObservations('')
  }

  function handleDecreaseQuant () {
    if (quant <= 1) return

    setQuant((prevState) => prevState - 1)
  }

  function handleIncreaseQuant () {
    setQuant((prevState) => prevState + 1)
  }

  function setComplement (type: string, complement: Addon, action?: 'plus' | 'minus', border?: boolean, pasta?: boolean) {
    const complementToBeAdded = currentSize.Complementos.find((selectionComplement) => selectionComplement.Nome === type)
    if (!complementToBeAdded) return

    const currentComplementItemSum = complementToBeAdded.Itens.reduce((prev, curr) => prev + curr.Quantidade, 0)

    if ((currentComplementItemSum === complementToBeAdded.QtdeMaxima) && (action === 'plus')) {
      return dispatch(setNotification(`Você deve selecionar até ${complement.Quantidade} itens dessa lista`))
    }

    const borderValue = !!border
    const pastaValue = !!pasta

    const setItemComplementProps = {
      type,
      complement,
      action,
      sizeCode: currentSize.Codigo,
      border: borderValue,
      pasta: pastaValue
    }

    dispatch(setItemComplement(setItemComplementProps))
  }

  function verifyComplements (): boolean {
    let value = true
    let sum = 0

    for (const i of currentSize.Complementos) {
      sum = i.Itens.reduce((prev, curr) => prev + curr.Quantidade, 0)

      if (i.Origem === 'B' && i.TipoItem === 'R') {
        if (item.Borda) {
          sum = sum + 1
        }
      }

      if (i.Origem === 'M' && i.TipoItem === 'R') {
        if (item.TipoMassa) {
          sum = sum + 1
        }
      }

      if (
        (sum < i.QtdeMinima) ||
        (sum > i.QtdeMaxima)
      ) {
        value = false
        break
      }
    }

    return value
  }

  function chooseProductType (type: 'normal' | 'meio a meio') {
    setProductType(type)
  }

  function halfAction (itemName: string) {
    const choosenItem = halfItems.find((halfItem) => halfItem.Nome === itemName)

    if (!choosenItem) return

    setHalf(choosenItem)
  }

  useEffect(() => {
    return () => {
      dispatch(clearItem())
    }
  }, [])

  useEffect(() => {
    if (!item.Codigo) return

    let items: number[] = [item.Codigo]

    if (halfItem?.Codigo) {
      items = [
        ...items,
        halfItem.Codigo
      ]
    }

    const env = process.env.REACT_APP_ITEM_PRICE_URI

    if (!env || !currentSize.Codigo || !items || !type || !storeParams) return

    const priceObject = {
      CodigoEmpresa: item.CodigoEmpresa,
      CodigoTamanho: currentSize.Codigo,
      CodigoProdutos: items,
      FlagPreco: type === 'drive' ? 'D' : type === 'retirada' ? 'R' : 'E'
    };

    (async () => {
      setIsPriceLoading(true)

      try {
        const response = await axios({
          method: 'POST',
          data: priceObject,
          url: `${storeParams.api}/${env}`,
          headers: {
            Authorization: `Bearer ${tokenData.access_token}`
          }
        })

        setIsPriceLoading(false)
        setPrices(response.data)
        
      } catch (err: any) {
        
        setIsPriceLoading(false)
        setError('Algum erro ocorreu, por favor, tente novamente mais tarde.')
      }
    })()
  }, [item.Codigo, halfItem?.Codigo])

  useEffect(() => {
    if (productType === 'normal') {
      setHalfItems([])
      setHalf(undefined)
      return
    }

    if (currentSize.PodeMeioMeio !== 'S' || currentSize.ItensMeioMeio.length < 1) {
      setHalfItems([])
      setHalf(undefined)
      chooseProductType('normal')
      return
    }

    const codes = currentSize.ItensMeioMeio.map((item) => item.Codigo)
    const env = process.env.REACT_APP_CARDAPIO_URI

    if (codes.length < 1 || !env) return

    (async () => {
      setIsLoading(true)

      try {
        const response = await axios({
          method: 'PATCH',
          url: `${storeParams.api}/${env}/${type}`,
          headers: {
            Authorization: `Bearer ${tokenData.access_token}`
          },
          data: codes
        })

        setIsLoading(false)
        setHalfItems(response.data)
      } catch (err: any) {
        chooseProductType('normal')
        setIsLoading(false)
      }
    })()
  }, [productType, currentSize.PodeMeioMeio, currentSize.ItensMeioMeio])

  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false
      return
    }

    dispatch(resetSize())
  }, [currentSize.Nome, productType])

  const basePrice = halfItem ? prices?.Preco : currentSize.Preco

  const complementsValue = currentSize.Complementos.reduce((prev, curr) => (
    prev + curr.Itens.reduce((previous, current) => previous + (current.Preco * current.Quantidade), 0)
  ), 0) * quant

  const additionalsValue = (
    currentSize.Adicionais.reduce((prev, curr) => prev + (curr.Checked ? (halfItemSize ? curr.PrecoMeioMeio : curr.PrecoCheio) : 0), 0) +
    (halfItemSize ? halfItemSize.Adicionais.reduce((prev, curr) => prev + (curr.Checked ? curr.PrecoMeioMeio : 0), 0) : 0) +
    (item.Borda ? item.Borda.Preco : 0) + (item.TipoMassa ? item.TipoMassa.Preco : 0)
  ) * quant

  const totalPrice = basePrice ? ((basePrice * quant) + additionalsValue + complementsValue) : 0

  const formCondition = (
    item.Ingredientes.length > 0
      ? (item.Ingredientes.reduce((prev, curr) => prev + (curr.Checked ? 1 : 0), 0) >= 1)
      : true
  ) && verifyComplements()

  function addToCart () {
    
    if (!prices) return
    
    setIsSuggestionsOpen(false)
    setIsSuggestionsLoading(false)

    let tastes = [
      {
        ...item,
        Tamanhos: [currentSize]
      }
    ]
    
    if (halfItem) {
      tastes = [
        ...tastes,
        {
          ...halfItem,
          Tamanhos: halfItem.Tamanhos.filter((size) => size.Codigo === currentSize.Codigo)
        }
      ]
    }

    const currentItemOnCart: ItemOnCart = {
      id: v4(),
      Tamanho: currentSize,
      Quantidade: quant,
      Observacao: observations,
      Sabores: tastes,
      TipoMassa: item.TipoMassa || null,
      Borda: item.Borda || null,
      ValorUnitario: totalPrice / quant,
      ValorTotal: totalPrice,
      additionalInfo: prices
    }

    const addItemProps = {
      cart: type === 'retirada' ? 'withdraw' : type === 'entrega' ? 'delivery' : 'drive' as 'withdraw' | 'delivery' | 'drive',
      item: currentItemOnCart
    }
    
    const tracking = new TrackingValidator(info);
    if (tracking.ValidateTracking()) {
      AddToCartTracking()
    }


     dispatch(addItem(addItemProps))
     dispatch(setNotification('Item adicionado ao carrinho'))
     navigate(`/loja/cardapio${search}`)
  }

  //Função de adicionar no carrinho
  async function handleCompleteRequest () {
    setIsSuggestionsLoading(true)

    try {
      const response = await axios({
        method: 'GET',
        url: `${storeParams.api}/${process.env.REACT_APP_SUGGESTIONS_URI}/${type.toUpperCase()}/${item.CodigoCategoria}`,
        headers: {
          Authorization: `Bearer ${tokenData.access_token}`
        }
      })
      //Aqui
      if (response.data.length < 1) {
        setIsSuggestionsLoading(false)
        return addToCart()
      }

      setSuggestions(response.data)
      setIsSuggestionsOpen(true)
      setIsSuggestionsLoading(false)
    } catch (err) {
      setIsSuggestionsLoading(false)
      addToCart()
    }
  }

  const footerProps = {
    quant,
    handleDecreaseQuant,
    handleIncreaseQuant,
    additionalsValue: additionalsValue + complementsValue,
    totalPrice,
    condition: formCondition,
    action: handleCompleteRequest
  }

  const pastaAddons = currentSize.Complementos.find((complement) => (complement.Origem === 'M') && (complement.TipoItem === 'R'))

  const borderAddons = currentSize.Complementos.find((complement) => (complement.Origem === 'B') && (complement.TipoItem === 'R'))

  return (
    <ItemOptionsContainer className="page_context">
      {
        (isLoading || isPriceLoading)
          ? (<ScaleLoader />)
          : (
            <>
              <SizeSelector
                item={item.Tamanhos}
                chooseSize={chooseSize}
                currentSize={currentSize}
                chooseProductType={chooseProductType}
                productType={productType}
                category={item.DescricaoCategoria}
              />
              { currentSize && (
                <Complements
                  addons={currentSize.Complementos}
                  setComplement={setComplement}
                  complements={currentSize.Complementos}
                  quant={quant}
                  sizeCode={currentSize.Codigo}
                  pasta={pastaAddons}
                  border={borderAddons}
                />
              )}
              {
                item.Ingredientes.length > 0 && (
                  <Ingredients
                    item={item}
                    setIngredient={setIngredient}
                    ingredientList={item.Ingredientes}
                    halfItemName={halfItem?.Nome}
                    halfItemIngredients={halfItem?.Ingredientes}
                  />
                )
              }
              {
                currentSize.Adicionais.length > 0 && (
                  <div className="additional">
                    <div className="section_header">
                      <p>Adicionais</p>
                    </div>
                    {
                      halfItem?.Nome && halfItemSize?.Adicionais && (
                        <div className="headings">
                          <p>{item.Nome}</p>
                          <p>{halfItem.Nome}</p>
                        </div>
                      )
                    }
                    <div className="additionals_wrapper">
                      <div className="main_item_additionals">
                        <button
                          className="choose_additional_button"
                          onClick={() => setIsAdditionalsSectionOpen(true)}
                        >
                          Escolha seus adicionais
                        </button>
                        <div className="additional_list">
                          {
                            currentSize.Adicionais.map((additional) => additional.Checked && (
                              <div className="additional_item" key={additional.Descricao}>
                                <div>
                                  <p className="additional_name">{additional.Descricao}</p>
                                  <p className="additional_pricing">
                                    + R$ {((halfItem ? additional.PrecoMeioMeio : additional.PrecoCheio) * quant).toFixed(2)}
                                  </p>
                                </div>
                                <button onClick={() => setAdditional(additional)}>
                                  <X size={24} color="#ffffff" />
                                </button>
                              </div>
                            ))
                          }
                        </div>
                      </div>
                      {
                        halfItemSize?.Adicionais && (
                          <div className="half_item_additionals">
                            <button
                              className="choose_additional_button"
                              onClick={() => setIsHalfAdditionalsSectionOpen(true)}
                            >
                              Escolha seus adicionais
                            </button>
                            <div className="additional_list">
                              {
                                halfItemSize.Adicionais.map((additional) => additional.Checked && (
                                  <div className="additional_item" key={additional.Descricao}>
                                    <div>
                                      <p className="additional_name">{additional.Descricao}</p>
                                      <p className="additional_pricing">
                                        + R$ {((halfItem ? additional.PrecoMeioMeio : additional.PrecoCheio) * quant).toFixed(2)}
                                      </p>
                                    </div>
                                    <button onClick={() => setHalfAdditional(additional)}>
                                      <X size={24} color="#ffffff" />
                                    </button>
                                  </div>
                                ))
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                )
              }
              <div className="observations">
                <div className="section_header">
                  <p>Observações</p>
                  <button onClick={cleanObservation}>Limpar</button>
                </div>
                <textarea rows={2} value={observations} onChange={handleChangeObservation}></textarea>
              </div>
              <Footer info={footerProps} itemCode={codigo} />
              { isAdditionalsSectionOpen && currentSize && (
                <Additionals
                  currentSelectedAdds={currentSize.Adicionais}
                  adds={currentSize.Adicionais}
                  close={() => setIsAdditionalsSectionOpen(false)}
                  set={setAdditional}
                  quant={quant}
                  halfItem={Boolean(halfItemSize)}
                />
              )
              }
              {
                isHalfAdditionalsSectionOpen && halfItemSize && (
                  <Additionals
                    currentSelectedAdds={halfItemSize.Adicionais}
                    adds={halfItemSize.Adicionais}
                    close={() => setIsHalfAdditionalsSectionOpen(false)}
                    set={setHalfAdditional}
                    quant={quant}
                    halfItem={Boolean(halfItemSize)}
                  />
                )
              }
              {
                productType === 'meio a meio' && !isLoading && halfItems.length > 0 && !halfItem?.Nome && (
                  <HalfSelector
                    close={() => chooseProductType('normal')}
                    action={halfAction}
                    items={halfItems}
                  />
                )
              }
              {
                isSuggestionsOpen && suggestions && (!isSuggestionsLoading) && (
                  <Suggestions suggestionList={suggestions} finalAction={addToCart} />
                )
              }
              {
                error && (
                  <OverlayNotification text={error} close={() => navigate(`/loja/cardapio${search}`)} />
                )
              }
              {
                isSuggestionsLoading && <ScaleLoader />
              }
              {
                notification && (
                  <Notification
                    text={notification}
                  />
                )
              }
            </>
            )
      }
    </ItemOptionsContainer>
  )
}

export default ItemOptions

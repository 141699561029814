import styled, { css } from 'styled-components'

type StoreCardProps = {
  image: string | undefined,
  name: string
}

type StoreCardStyleProps = {
  image: string | undefined
}

const StoreCardContainer = styled.div<StoreCardStyleProps>`
  border-radius: .8rem;

  width: 18rem;
  height: 12rem;

  display: inline-block;

  box-shadow: 3px 3px 9px -8px rgb(0 0 0 / 52%);
  pointer-events: none;

  margin-bottom: 1rem;
  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  .image {
    height: 74%;

    ${({ image }) => image && css`
      background-image: url(${image});
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
    `}

    border-top-left-radius: .8rem;
    border-top-right-radius: .8rem;

    flex: 1;
  }

  p {
    color: #464646;

    font-size: 1.1rem;
    font-weight: 700;

    margin: .8rem .4rem;
  }
`

function StoreCard({ image, name }: StoreCardProps) {
  return (
    <StoreCardContainer image={image}>
      <div className="image" />
      <p>{name}</p>
    </StoreCardContainer>
  )
}
export default StoreCard

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { ItemCardapio } from '../../../types/cardapio'
import { cardapioService } from './cardapioService'
 
const initialState = {
  cardapio: [] as ItemCardapio[],
  categories: [] as string[],
  //type: 'retirada' as 'entrega' | 'retirada' | 'drive' | 'salao',
  type: '' as 'entrega' | 'retirada' | 'drive' | 'salao' | '',
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: ''
}

export type GetCardapioProps = {
  option: string,
  token: string,
  domain: string
}

export const getCardapio = createAsyncThunk(
  'cardapio/get',
  async (props: GetCardapioProps, thunkApi) => {
    try {
      const response = await cardapioService.getCardapio(props.option, props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

const cardapioSlice = createSlice({
  name: 'cardapio',
  initialState,
  reducers: {
    reset: (state) => {
      state.isSuccess = false
      state.isError = false
      state.isLoading = false
      state.message = ''
    },
    setType: (state, action) => {
      state.type = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardapio.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getCardapio.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.cardapio = action.payload

        state.categories = Array.from(
          new Set(
            state.cardapio.map((item) => item.DescricaoCategoria)
          )
        )
      })
      .addCase(getCardapio.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
  }
})

export const { reset, setType } = cardapioSlice.actions
export default cardapioSlice.reducer

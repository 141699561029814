import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { ItemCardapio } from '../../types/cardapio'
import { CategoryContainer } from './styles'
import { RootState } from '../../store/store'
import CategoryItemCard from './CategoryItemCard'

type CategoryProps = {
  items: ItemCardapio[],
  chefSuggestion: boolean,
  title: string,
  id?: string
}

function Category ({ items, chefSuggestion, title, id }: CategoryProps) {
  const { type } = useSelector((state: RootState) => state.cardapio)

  const { search } = useLocation()

  const navigate = useNavigate()

  function navigateToItem (codigo: number) {
    if (type === 'salao') return

    navigate(`/loja/cardapio/${codigo}${search}`)
  }

  return (
      <CategoryContainer chef={chefSuggestion} id={id}>
        <h3>{title}</h3>
        <div className="items">
          {items && items.map((item) => (
            <CategoryItemCard
              key={item.Codigo}
              item={item}
              navigateToItem={navigateToItem}
              chefSuggestion={chefSuggestion}
            />
          ))}
        </div>
      </CategoryContainer>
  )
}

export default Category

import axios from 'axios'

export const dneInstance = axios.create({
  baseURL: process.env.REACT_APP_DNE_URI,
  auth: {
    username: String(process.env.REACT_APP_DNE_USERNAME),
    password: String(process.env.REACT_APP_DNE_PASSWORD)
  },
  method: 'GET'
})

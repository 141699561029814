import styled from 'styled-components'

type AdditionalsContainerProps = {
  background: string
}

export const AdditionalsContainer = styled.section<AdditionalsContainerProps>`
  background: #ffffff;

  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;

  display: flex;
  flex-direction: column;

  z-index: 10000;

  .additionals_list {
    overflow-y: auto;

    flex: 1;
  }

  .additional_box {
    border-bottom: 1px solid #dddddd;

    display: flex;
    align-items: center;

    padding: 1.6rem 1.2rem;

    .add_name {
      color: #464646;
      font-size: 1.2rem;
      font-weight: 700;

      margin-bottom: .4rem;
    }

    .add_price {
      color: #aaaaaa;
      font-weight: 700;
    }

    .checkmark {
      margin-right: 2rem;
    }
  }

  footer {
    background: ${({ background }) => background || '#464646'};

    padding: 1.2rem;

    .button_wrapper {
      display: flex;
      justify-content: flex-end;
    }

    button {
      background: none;

      display: flex;
      align-items: center;
    }

    svg {
      filter: drop-shadow(1px 1px 2px rgb(0 0 0 / 52%));
    }

    span {
      color: #ffffff;
      font-size: 1.6rem;
      font-weight: 700;

      margin-right: .6rem;
    }
  }
`

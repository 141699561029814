import { Minus, Plus } from 'lucide-react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../store/store'
import { Addon } from '../../../../types/cardapioItem'

type ComplementProps = {
  info: Addon,
  type: string,
  setComplement: (name: string, arg: Addon, act?: 'plus' | 'minus') => void,
  qtd: {
    min: number,
    max: number
  },
  checked: () => boolean,
  currentQuant: number
}

function Complement ({ info, type, setComplement, qtd, checked, currentQuant }: ComplementProps) {
  let fieldType: 'multiple' | 'unique' = 'unique'

  const { Nome, Preco, Codigo } = info

  const { storeParams } = useSelector((state: RootState) => state.token)
  
  const [imageFailed, setImageFailed] = useState<boolean>(false)

  if (qtd.max > 1) {
    fieldType = 'multiple'
  }

  function handlePlusMultipleField () {
    setComplement(
      type, 
      { Nome, Preco, Quantidade: info.Quantidade, Codigo },
      'plus'
    )
  }

  function handleMinusMultipleField () {
    if (info.Quantidade - 1 < 0) return

    setComplement(
      type, 
      { Nome, Preco, Quantidade: info.Quantidade, Codigo },
      'minus'
    )
  }

  return (
    <label>
      <div className="complement_details">
        {
          !imageFailed && (
            <div className="image">
              <img
                src={`${storeParams.complement}/${Codigo}.jpg`}
                alt="Imagem do complemento"
                onError={() => setImageFailed(true)}
              />
            </div>
          )
        }
        <div className="definition">
          <div className="complement_name">{Nome}</div>
          <div className="complement_pricing">+ R$ {(Preco * currentQuant).toFixed(2)}</div>
        </div>
      </div>
      {
        fieldType === 'unique'
          ? (
              <div className="custom_checkbox">
                <input
                  type="checkbox"
                  name={type}
                  onChange={() => setComplement(type, { Nome, Preco, Quantidade: 1, Codigo })}
                  checked={checked()}
                />
                <span className="checkmark" />
              </div>
            )
          : (
            <div className="multiple_checkbox">
              <button
                type="button"
                onClick={handleMinusMultipleField}
              >
                <Minus color="#990000" size={24} />
              </button>
              <span>{info.Quantidade}</span>
              <button
                type="button"
                onClick={handlePlusMultipleField}
              >
                <Plus color="#439A00" size={24} />
              </button>
            </div>
          )
      }
    </label>
  )
}

export default Complement

import styled from 'styled-components'

type HeaderContainerTypes = {
  background: string,
}

export const HeaderContainer = styled.header<HeaderContainerTypes>`
  background-image: url(${({ background }) => background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  position: relative;

  color: #ffffff;

  z-index: 500;

  display: flex;
  justify-content: center;

  text-shadow: 2px 2px 4px #00000088;

  .store_info {
    position: relative;

    padding: 2rem 0;

    .go_back_button {
      background: none;

      position: absolute;

      top: 50%;
      left: 3rem;
      transform: translateY(-50%);
    }

    .requests_button {
      position: absolute;

      top: 50%;
      right: 3rem;
      transform: translateY(-50%)
    }
  }

  .description {
    padding: 1.6rem 8.2rem;

    h1 {
      font-size: 2rem;
    }

    span {
      font-size: 1.4rem;
    }
  }

  nav {
    background: #000000aa;
    padding: 0 1.4rem;

    @media (min-width: 1024px) {
      border-top-left-radius: 1.6rem;
      border-top-right-radius: 1.6rem;
    }

    ul {
      display: flex;

      li {
        flex: 1;

        display: flex;
        justify-content: center;

        position: relative;

        &:hover {
          background: #00000066;
        }

        a::after {
          content: '';
          background: #ea0e4b;

          position: absolute;
          left: 0;
          bottom: .02rem;

          width: 100%;
          height: .175rem;
        }

        a.active::after {
          transform: initial;
        }

        &:first-child {

          a.active::after {
            transform: initial;
          }

          a::after {
            transform: scale(0, 1);
            transform-origin: 100% 0%;
            transition: transform .3s;
          }

          a:hover::after {
            transform: scale(1, 1);
          }
        }

        &:last-child {

          a.active::after {
            transform: initial;
          }

          a::after {
            transform: scale(0, 1);
            transform-origin: 0% 100%;
            transition: transform .3s ease;
          }

          a:hover::after {
            transform: scale(1, 1);
          }
        }
      }
    }

    a {
      color: #ffffff;
      font-size: 1.4rem;
      text-align: center;
      
      display: block;

      width: 100%;

      padding: .8rem 0;
    }
  }

  button svg {
    filter: drop-shadow(2px 2px 4px #00000088);
  }
`

import { ItemCardapio } from '../../../types/cardapio'
import Categories from '../../Cardapio/components/Categories'

type ResultsProps = {
  results: ItemCardapio[],
  sendWarning: (arg: string) => void,
  categories: string[]
}

function Results ({ results, sendWarning, categories }: ResultsProps) {
  if (!results) return null

  if (results.length === 0) {
    return null
  }

  return (
    <div className="page_context">
      <Categories cardapio={results} categories={categories} searchPage />
    </div>
  )
}

export default Results

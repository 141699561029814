import { StoreInfo } from '../types/storeInfo';



export class TrackingValidator {
    public _info!:StoreInfo;

    constructor(Info: StoreInfo) {
        this._info = Info;
    }

    //Lógica de autorização para usar o tracking system do facebook
    public ValidateTracking(): boolean {
        return this._info.Storage === 'BLC';
    }

}




import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import SearchBar from '../../../../components/SearchBar'
import { ItemCardapio } from '../../../../types/cardapio'
import HalfItemButton from './HalfItemButton'
import HalfSelectorContainer from './styles'

type HalfSelectorProps = {
  close: () => void,
  action: (name: string) => void,
  items: ItemCardapio[]
}

function HalfSelector ({ close, items, action }: HalfSelectorProps) {
  const halfSelectorPortal = document.getElementById('half_selector_portal')

  const [searchValue, setSearchValue] = useState<string>('')

  useEffect(() => {
    const htmlBody = document.querySelector('body')
    if (!htmlBody) return

    const topPos = window.scrollY

    htmlBody.style.position = 'fixed'
    htmlBody.style.top = `-${topPos}px`

    return () => {
      const oldTopPos = htmlBody.style.top
      htmlBody.style.position = ''
      htmlBody.style.top = ''
      window.scrollTo(0, parseInt(oldTopPos || '0') * -1)
    }
  }, [])

  if (!halfSelectorPortal) return null

  const filteredItems =
    searchValue.length > 0
      ? items.filter((item) => item.Search.toUpperCase().includes(searchValue.toUpperCase()))
      : items

  return ReactDOM.createPortal((
    <HalfSelectorContainer>
      <SearchBar
        searchValue={searchValue}
        handleChangeSearchValue={(e) => setSearchValue(e.target.value)}
        cleanSearchValue={() => setSearchValue('')}
        handleGoBack={close}
        placeholder="Selecione sua outra metade..."
      />
      <div className="items">
        <div className="page_context">
          {
            filteredItems.map((item) => (
              <HalfItemButton
                key={item.Codigo}
                item={item}
                action={action}
              />
            ))
          }
        </div>
      </div>
    </HalfSelectorContainer>
  ), halfSelectorPortal)
}

export default HalfSelector

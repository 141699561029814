import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { VoucherItem } from '..'
import { RootState } from '../../../store/store'

type CartFooterProps = {
  totalPrice: number,
  handleSubmit: () => void,
  type: string,
  discount: number,
  voucher: VoucherItem | null
}

const CartFooterContainer = styled.footer`
  background: #ffffff;

  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;

  padding: 1.6rem .6rem;

  box-shadow: 0px 0px 5px -1px #333333;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  p {
    font-size: 1.4rem;
    font-weight: 700;
  }

  span {
    color: #666666;

    font-size: 1.1rem;
    font-weight: 700;

    display: block;

    margin-bottom: .6rem;
  }

  button {
    background: #ff0000;
    border-radius: 1.6rem;
    color: #ffffff;
    
    font-size: 1.4rem;
    font-weight: 700;

    padding: .8rem 1.4rem;

    box-shadow: 0px 0px 5px -1px #333333;
  }
`

function CartFooter ({ totalPrice, handleSubmit, type, discount, voucher }: CartFooterProps) {
  const { info } = useSelector((state: RootState) => state.info)

  return (
    <CartFooterContainer>
      <div className="page_context">
        <div id="total_price">
          {
            voucher && (
              <span>Cupom de desconto: - R$ {voucher.ValorCupom.toFixed(2)}</span>
            )
          }
          {
            discount > 0 && (
              <span>Desconto: - R$ {discount.toFixed(2)}</span>
            )
          }
          { type === 'delivery' && info.TaxaEntrega && (
            <span>Taxa de entrega: + R$ {info.TaxaEntrega.toFixed(2)}</span>
          ) }
          <p>Total: R$ {(totalPrice - (voucher?.ValorCupom || 0)).toFixed(2)}</p>
        </div>
        <button type="button" onClick={handleSubmit}>
          Realizar pedido
        </button>
      </div>
    </CartFooterContainer>
  )
}

export default CartFooter

const mock = {
  home: {
    colors: {
      button: {
        background: '#940f11',
        text: '#ffffff'
      }
    }
  },
  measures: {
    borderRadius: '.4rem'
  }
}

export default mock

import { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: Montserrat, Arial, Helvetica, sans-serif;
  }

  :root {
    position: relative;
    font-size: 62.5%;
  }

  html {
    scroll-behavior: smooth;
  }

  html, body {
    overflow-x: hidden;
  }

  button {
    background: none;
    border: 0;
  }

  a {
    text-decoration: none;
  }

  ul, ol {
    list-style: none;
  }

  .custom_checkbox {
    input {
      display: none;
    }

    .checkmark{
      background: #cccccc;
      outline: 1px solid #888888;
      border-radius: 50%;

      display: block;

      position: relative;

      width: 24px;
      height: 24px;

      margin-right: .4rem;
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;

      left: 9px;
      top: 4px;

      width: 5px;
      height: 11px;
      border: solid white;
      border-width: 0 3px 3px 0;

      transform: rotate(45deg);
    }

    input:checked ~ .checkmark {
      background: #ff0000;
      outline-color: #ff0000;

      &:after {
        display: block;
      }
    }
  }

  .page_context {
    width: 100%;
    max-width: 1024px;

    margin: 0 auto;
  }

  .small_context {
    width: 100%;
    max-width: 512px;

    margin: 0 auto;
  }

  .black_text {
    color: #000000;
  }

  .white_text {
    color: #ffffff;
  }

  .dark_film {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background: #00000066;
  }
`

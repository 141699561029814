import styled from 'styled-components'

type SuggestionCardProps = {
  image: string,
  name: string,
  size: string,
  action: () => void,
  selected: boolean
}

const SuggestionCardContainer = styled.div`
  flex: none;
  
  border: 3px solid #cccccc;
  border-radius: 1rem;

  width: 11rem;
  height: 13rem;

  margin-right: 2rem;

  &:last-child {
    margin-right: 0;
  }

  .image {
    height: 8rem;

    img {
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;

      object-fit: cover;
      object-position: center;

      width: 100%;
    }
  }

  .description {
    text-align: center;

    color: #121212;
    font-size: 1.1rem;
    font-weight: 700;

    p:first-child {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      white-space: initial;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &.selected {
    border-color: #ff0000;
  }
`

function SuggestionCard({ image, name, size, selected, action }: SuggestionCardProps) {
  return (
    <SuggestionCardContainer className={selected ? 'selected' : undefined} onClick={action}>
      <div className="image">
        <img src={image} alt="Imagem da sugestão" />
      </div>
      <div className="description">
        <p>{name}</p>
        <p>{size}</p>
      </div>
    </SuggestionCardContainer>
  )
}
export default SuggestionCard

import { ItemOnCart } from '../../../types/cart'
import { CartItemsContainer } from '../styles'
import ItemCard from './ItemCard'

type CartItemsProps = {
  items: ItemOnCart[],
  recalculate: () => void
}

function CartItems ({ items, recalculate }: CartItemsProps) {
  return (
    <CartItemsContainer>
      {
        items.map((item) => <ItemCard key={item.id} item={item} recalculate={recalculate} />)
      }
    </CartItemsContainer>
  )
}

export default CartItems

import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AddressType } from '../../../types/address'
import { StoreInfo, StoreParams } from '../../../types/storeInfo'
import { infoService } from './infoService'

type GetStoreInfoProps = {
  token: string,
  domain: string
}

type GetStoreParamsProps = {
  token: string,
  body: AddressType,
  storeId: string,
  domain: string
}

type UpdateFeeProps = {
  fee: number
}

const initialState = {
  info: {} as StoreInfo,
  params: {} as StoreParams,
  isLoading: false,
  isError: false,
  isSuccess: false,
  message: ''
}

export const getStoreInfo = createAsyncThunk(
  'info/get_store_info',
  async (props: GetStoreInfoProps, thunkApi) => {
    try {
      const response = await infoService.getStoreInfo(props.token, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

export const getStoreParams = createAsyncThunk(
  'info/get_store_params',
  async (props: GetStoreParamsProps, thunkApi) => {
    try {
      const response = await infoService.getStoreParams(props.token, props.body, props.storeId, props.domain)

      if (response?.status !== 200) {
        throw new Error('Invalid API response')
      }

      return response.data
    } catch (err: any) {
      const message =
        (err.response &&
          err.response.data &&
          err.response.data.message) ||
        err.message ||
        String(err)

      return thunkApi.rejectWithValue(message)
    }
  }
)

const infoSlice = createSlice({
  name: 'info',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateFee: (state, action: PayloadAction<UpdateFeeProps>) => {
      state.info = {
        ...state.info,
        TaxaEntrega: action.payload.fee
      }
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStoreInfo.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStoreInfo.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.info = action.payload
      })
      .addCase(getStoreInfo.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
      .addCase(getStoreParams.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getStoreParams.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.params = action.payload
      })
      .addCase(getStoreParams.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = String(action.payload)
      })
  }
})

export const { reset, updateFee } = infoSlice.actions
export default infoSlice.reducer

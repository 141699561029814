export default function getRelativeLuminance (hexColor: string) {
  const fragmentedHex = hexColor.match(/.{1,2}/g)

  if (!fragmentedHex) return 0

  const rgb = [
    parseInt(fragmentedHex[0], 16),
    parseInt(fragmentedHex[1], 16),
    parseInt(fragmentedHex[2], 16)
  ]

  const RsRGB = rgb[0] / 255
  const GsRGB = rgb[1] / 255
  const BsRGB = rgb[2] / 255

  const luminanceLevel = 0.2126 * RsRGB + 0.7152 * GsRGB + 0.0722 * BsRGB

  return luminanceLevel
}
